import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, View } from 'react-native';
import { HasNavigation } from './Navigation';
import RemindersScreen from './RemindersScreen';
import SenopiApi from './api/SenopiApi';
import IconButton from './elements/IconButton';
import LanguagePicker from './elements/LanguagePicker';
import LinkText from './elements/LinkText';
import SButton from './elements/SButton';

const HomeScreen: FC<HasNavigation> = ({ navigation, style, ...rest }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [showPerformance, setShowPerformance] = useState(false);

  const onLogout = async () => {
    const api = await SenopiApi();
    await api.logout();
    navigation('login', false);
  };

  const loadProfile = async () => {
    const api = await SenopiApi();
    try {
      const user = api.userInfo;
      setShowPerformance(user.type == 'user');
      setFirstName(user.firstName || 'Anonymous');
      setLastName(user.lastName || '');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <View style={[styles.container, style]}>
      <LanguagePicker navigation={navigation} dialog={rest.dialog} />
      <Image
        source={require('../assets/logo_main.png')}
        style={{ width: 198, height: 50, marginTop: 48 }}
      />
      <Image source={require('../assets/icon.png')} style={styles.photo} resizeMode='cover' />
      <Text style={styles.title}>
        {firstName} {lastName}
      </Text>
      <View style={styles.form}>
        {showPerformance && (
          <SButton
            label={t('home_performance')}
            style={styles.form_button}
            onPress={() => navigation('performance', true)}
          />
        )}
        <SButton
          label={t('home_progress')}
          style={styles.form_button}
          onPress={() => navigation('progress', true)}
        />
        <SButton
          label={t('home_reminders')}
          style={styles.form_button}
          onPress={() => navigation(RemindersScreen, true)}
        />
        <LinkText
          label={t('home_side_effects')}
          style={styles.form_button}
          onPress={() => navigation('adverseReport', true)}
        />
      </View>
      <IconButton
        label={t('home_sign_out')}
        icon={require('../assets/icon_signout.png')}
        iconWidth={18}
        iconHeight={16}
        onPress={onLogout}
      />
      <View style={{ flexGrow: 1 }} />
      <Image
        source={require('../assets/logo_developed_by.png')}
        resizeMode='contain'
        style={{ width: 97, height: 46, marginBottom: 10, marginTop: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  photo: {
    marginTop: 48,
    width: 120,
    height: 120,
    borderWidth: 4,
    borderColor: '#48A9C5',
    borderRadius: 60,
  },

  title: {
    color: '#48A9C5',
    fontSize: 29,
    fontFamily: 'SegoeRegular',
    marginTop: 19,
  },

  token: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'SegoeRegular',
    marginTop: 5,
  },

  form: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 49,
    marginBottom: 46,
  },

  form_button: {
    marginBottom: 17,
  },
});

export default HomeScreen;
