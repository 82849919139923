import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { HasNavigation } from '../Navigation';
import SenopiApi from '../api/SenopiApi';
import UserProfile from '../api/model/UserProfile';
import ProgressBar from '../elements/ProgressBar';
import ProgressStatsItem from '../elements/ProgressStatsItem';

interface Props extends HasNavigation {
  user: UserProfile;
}

const getTotalSessions = () => 36;

export default ({ user, dialog, style }: Props) => {
  const { t } = useTranslation();

  const [nextSession, setNextSession] = useState<DateTime | undefined>();
  const [upcomingSessions, setUpcomingSessions] = useState<Array<DateTime> | undefined>();

  const loadReminders = async () => {
    const api = await SenopiApi(user);
    try {
      const remindersWeekdays = {
        monday: t('reminders_weekdays.monday'),
        tuesday: t('reminders_weekdays.tuesday'),
        wednesday: t('reminders_weekdays.wednesday'),
        thursday: t('reminders_weekdays.thursday'),
        friday: t('reminders_weekdays.friday'),
        saturday: t('reminders_weekdays.saturday'),
        sunday: t('reminders_weekdays.sunday'),
      };
      const schedule = (await api.scheduleGet()).schedule;
      if (schedule) {
        const weekdays: Array<string> = Object.keys(remindersWeekdays);
        const nextDates = weekdays
          .filter((x) => schedule[x])
          .filter((x) => schedule[x]?.enabled)
          .map((x) => {
            const reminder = schedule[x];
            let date = DateTime.now().set({
              hour: reminder?.hour,
              minute: reminder?.minutes,
              weekday: weekdays.indexOf(x) + 1,
            });

            if (date < DateTime.now()) {
              date = date.plus({ weeks: 1 });
            }

            return date;
          })
          .sort((a, b) => a.diff(b).milliseconds);

        if (nextDates.length > 0) {
          setUpcomingSessions(nextDates);
          setNextSession(nextDates[0]);
        }
      }
    } catch (e) {
      console.error('Schedule not available', e);
    }
  };

  const [completedSessions, setCompletedSessions] = useState(0);
  const [lastSession, setLastSession] = useState<Date | undefined>();
  const [firstSession, setFirstSession] = useState<Date>(new Date());

  const requestStreams = async () => {
    const api = await SenopiApi(user);
    const stats = await api.exercisesStats();
    setLastSession(stats.lastDate && new Date(stats.lastDate));
    setFirstSession((stats.firstDate && new Date(stats.firstDate)) || new Date());
    setCompletedSessions(stats.count || 0);
  };

  const load = async () => {
    dialog.showLoading();
    await Promise.all([requestStreams(), loadReminders()]);
    dialog.hideLoading();
  };

  useEffect(() => {
    load();
  }, []);

  const remainingSessions = Math.max(getTotalSessions() - completedSessions, 0);

  console.log(Math.floor(Math.abs(DateTime.fromJSDate(firstSession).diffNow('weeks').weeks)));
  const completedWeeks = Math.min(
    12,
    Math.floor(Math.abs(DateTime.fromJSDate(firstSession).diffNow('weeks').weeks)),
  );
  const remainingWeeks = 12 - completedWeeks;

  const expectedSessions =
    completedSessions / (1 + completedWeeks) > 3 ? 3 * (1 + completedWeeks) : completedSessions;

  // if((completedSessions/(1 + completedWeeks)) > 3) (3 * (1 + completedWeeks)) else completedSessions)/getTotalSessions()

  return (
    <View style={[styles.container, style]}>
      <ProgressBar maxValue={getTotalSessions()} value={expectedSessions} />
      <View style={styles.schedule_container}>
        <View style={styles.stats_info}>
          <ProgressStatsItem
            style={[{ marginEnd: 4, width: '50%' }, styles.stats_block]}
            title={t('progress_main_completed')}
            weeks={completedWeeks}
            sessions={completedSessions}
          />
          <ProgressStatsItem
            style={[{ marginStart: 4, width: '50%' }, styles.stats_block]}
            title={t('progress_main_remaining')}
            weeks={remainingWeeks}
            sessions={remainingSessions}
          />
        </View>
        {nextSession && (
          <View style={[styles.schedule_item_container, styles.stats_block]}>
            <Text style={styles.schedule_item_label}>{t('progress_main_next_session')}:</Text>
            <View style={styles.schedule_item_value}>
              <Text style={styles.schedule_item_time}>{nextSession.toFormat('ccc dd.LL')}</Text>
              <Text style={styles.schedule_item_time}>{nextSession.toFormat('HH:mm')}</Text>
            </View>
            <Text style={styles.schedule_item_sub}> </Text>
          </View>
        )}
        {upcomingSessions && upcomingSessions.length > 0 && (
          <View style={[styles.schedule_item_container, styles.stats_block]}>
            <Text style={[styles.schedule_item_label, { marginTop: 10 }]}>
              {t('progress_main_upcoming_sessions')}:
            </Text>
            {upcomingSessions.map((x) => (
              <View key={Math.random()} style={[styles.schedule_item_value, { marginBottom: 12 }]}>
                <Text style={styles.schedule_item_time}>{x.toFormat('ccc dd.LL')}</Text>
                <Text style={styles.schedule_item_time}>{x.toFormat('HH:mm')}</Text>
              </View>
            ))}
          </View>
        )}
        {lastSession && (
          <View
            style={[
              styles.schedule_item_container,
              styles.stats_block,
              { paddingTop: 26, paddingBottom: 26 },
            ]}
          >
            <Text style={styles.schedule_item_label}>{t('progress_main_last_session')}:</Text>
            <View style={styles.schedule_item_value}>
              <Text style={styles.schedule_item_time}>
                {DateTime.fromJSDate(lastSession).toFormat('ccc dd.LL')}
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    textAlign: 'center',
    marginBottom: 26,
  },

  stats_info: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 15,
    width: '100%',
  },

  stats_block: {
    backgroundColor: '#0A100FB3',
    borderRadius: 16,
    paddingStart: 10,
    paddingEnd: 10,
    paddingBottom: 10,
    paddingTop: 4,
  },

  schedule_container: {
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 400,
  },

  schedule_item_container: {
    marginTop: 33,
    alignSelf: 'center',
    width: '100%',
  },

  schedule_item_label: {
    color: '#48A9C5',
    fontFamily: 'SegoeRegular',
    fontSize: 15,
    lineHeight: 20,
    textAlign: 'center',
    marginBottom: 8,
  },

  schedule_item_sub: {
    color: '#FFFFFFB2',
    fontFamily: 'SegoeRegular',
    fontSize: 12,
    lineHeight: 20,
    textAlign: 'center',
  },

  schedule_item_value: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    // maxWidth: 200
  },

  schedule_item_time: {
    color: '#FFFFFF',
    fontFamily: 'SegoeRegular',
    fontSize: 20,
    lineHeight: 27,
  },
});
