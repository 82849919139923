import { FC } from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native';

interface SButtonProps extends TouchableOpacityProps {
  label: string;
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  type?: 'contained' | 'text';
}

const SButton: FC<SButtonProps> = (props) => {
  const { type } = props;
  switch (type) {
    case 'contained':
      return <Contained {...props} />;
    case 'text':
      return <BText {...props} />;
    default:
      return <Contained {...props} />;
  }
};

const Contained: FC<SButtonProps> = ({ label, style, ...rest }) => {
  return (
    <TouchableOpacity style={[styles.button, style]} {...rest}>
      <Text style={styles.label}>{label}</Text>
    </TouchableOpacity>
  );
};

const BText: FC<SButtonProps> = ({ label, style, labelStyle, ...rest }) => {
  return (
    <TouchableOpacity style={[style]} {...rest}>
      <Text style={[styles.text_button, labelStyle]}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#48A9C5',
    borderRadius: 52,
    justifyContent: 'center',
    paddingStart: 14,
    paddingTop: 16,
    paddingEnd: 14,
    paddingBottom: 16,
  },

  label: {
    fontFamily: 'SFProSemibold',
    fontSize: 20,
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'uppercase',
  },

  text_button: {
    textAlign: 'center',
    color: '#48A9C5',
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
  },
});

export default SButton;
