import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    maxWidth: 290,
    maxHeight: 350,
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#fff',
    opacity: 0.8,
    fontSize: 18,
    marginTop: 40,
    marginBottom: 70,
    textAlign: 'center',
  },
  title_main: {
    fontSize: 20,
    color: '#fff',
    marginTop: 15,
    marginBottom: 38,
  },
});
