import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { HasNavigation } from '../../Navigation';
import SenopiApi from '../../api/SenopiApi';
import {
  EnvironmentEnum,
  HeadsetSettings,
  HeadsetSettingsModule,
  MusicEnum,
} from '../../api/model/HeadsetSettings';
import UserProfile from '../../api/model/UserProfile';
import { DialogInfo } from '../../elements/DialogInfo';
import HeaderPane from '../../elements/HeaderPane';
import SButton from '../../elements/SButton';
import {
  setSessionCurrentScreenAction,
  setSessionSettingsAction,
} from '../../redux/reducers/session.reducer';
import {
  getSessionCurrentScreenSelector,
  getSessionSettingsSelector,
} from '../../redux/selectors/session.selector';
import { Environment } from './components/Environment';
import { Exercises } from './components/Exercises';
import { Music } from './components/Music';
import { styles } from './styles';

interface Props extends HasNavigation {
  user?: UserProfile;
}

export default ({ navigation, style, dialog, user }: Props) => {
  const dispatch = useDispatch();
  const sessionSettings = useSelector(getSessionSettingsSelector);
  const [currentUser, setCurrentUser] = useState<UserProfile>();
  const currentScreen = useSelector(getSessionCurrentScreenSelector);
  const [dialogVisible, setDialogVisible] = useState(false);

  const { t, i18n } = useTranslation();

  const onNextPress = () => {
    if (currentScreen === 2) {
      showTurnOnDialog();
    } else {
      dispatch(setSessionCurrentScreenAction(currentScreen + 1));
    }
  };

  const onBackPress = () => {
    if (currentScreen === 0) {
      navigation('..');
    } else {
      dispatch(setSessionCurrentScreenAction(currentScreen - 1));
    }
  };

  const onChange = (
    key: keyof typeof sessionSettings,
    value: string | HeadsetSettingsModule[] | null,
  ) => {
    dispatch(setSessionSettingsAction({ ...sessionSettings, [key]: value }));
  };

  const disableButton = useCallback(() => {
    switch (currentScreen) {
      case 2:
        return !sessionSettings.modules.filter((e) => !!e).length;
      default:
        return false;
    }
  }, [sessionSettings]);

  const getButtonLabel = useMemo(() => {
    switch (currentScreen) {
      case 0:
        return sessionSettings.environment
          ? t('live_session_setup_next')
          : t('live_session_setup_skip');
      case 1:
        return sessionSettings.music ? t('live_session_setup_next') : t('live_session_setup_skip');
      case 2:
        return t('superviser_start_session_start');
      default:
        return '';
    }
  }, [sessionSettings, currentScreen]);

  useEffect(() => {
    const load = async () => {
      dialog.showLoading();
      const api = await SenopiApi(user);
      await loadSettings();
      try {
        const result = await api.headsets();
        const userList = await api.users();
        if (result.length) {
          setCurrentUser(userList.find((u) => u.username === result[0].user?.username));
        }
      } catch (err) {
      } finally {
        dialog.hideLoading();
      }
    };

    load();
  }, []);

  const loadSettings = async () => {
    try {
      const api = await SenopiApi(user);
      const settings = await api.getHeadsetSettings();
      setupSettings({
        ...settings,
        modules: settings.modules.map((el) => ({ ...el, level: 0 })),
      });
    } catch (err) {}
  };

  const setupSettings = async (settings: HeadsetSettings) => {
    dispatch(setSessionSettingsAction(settings));
  };

  const showTurnOnDialog = async () => {
    await updateHeadsetSettings();
    setDialogVisible(true);
  };

  const startSession = async (): Promise<void> => {
    if (!currentUser) {
      return;
    }
    dialog.showLoading();
    const api = await SenopiApi(user);

    const result = await api.liveSessions();
    const session = result.find((s) => s.userAccessInfo.username === currentUser.username);
    dialog.hideLoading();
    if (!session) {
      showTurnOnDialog();
    } else {
      navigation('subadminExerciseInfo', true, {
        exercise: session.exercise,
        user: currentUser,
      });
      dispatch(setSessionCurrentScreenAction(0));
    }
  };

  const updateHeadsetSettings = async () => {
    const api = await SenopiApi(user);
    const data: HeadsetSettings = {
      language: i18n.language,
      environment: sessionSettings.environment as EnvironmentEnum,
      music: sessionSettings.music as MusicEnum,
      modules: sessionSettings.modules.filter((el) => !!el.name),
    };
    return await api.updateHeadsetSettings(data);
  };

  return (
    <>
      <View style={[style, styles.container, { width: '100%' }]}>
        <HeaderPane onBackPressed={onBackPress} style={{ width: '100%' }} />
        <ScrollView
          style={{ flex: 1, width: '100%' }}
          contentContainerStyle={{ alignItems: 'center', width: '100%' }}
        >
          <View style={{ height: '100%', alignItems: 'center', width: '100%' }}>
            {currentScreen === 0 && (
              <Environment
                value={sessionSettings.environment}
                onChange={(value) => onChange('environment', value)}
              />
            )}
            {currentScreen === 1 && (
              <Music value={sessionSettings.music} onChange={(value) => onChange('music', value)} />
            )}
            {currentScreen === 2 && (
              <Exercises
                modules={sessionSettings.modules}
                onChange={(value) => onChange('modules', value)}
                navigation={navigation}
                currentUser={currentUser}
              />
            )}
          </View>
          <SButton
            disabled={disableButton()}
            label={getButtonLabel}
            onPress={onNextPress}
            style={styles.button}
          />
        </ScrollView>
      </View>
      <DialogInfo
        isVisible={dialogVisible}
        setIsVisible={setDialogVisible}
        message={t('superviser_start_session_turn_on')}
        onPress={startSession}
      />
    </>
  );
};
