import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  info_title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
    marginVertical: 80,
    marginHorizontal: '10%',
  },
});
