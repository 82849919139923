import { Buffer } from 'buffer';
import Constants from 'expo-constants';
import { useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import UserProfile from '../api/model/UserProfile';
import { backendConfig } from '../api/SenopiApi';

interface ReturnType<T> {
  message: T | undefined;
  sendCommand: (name: string, message: any) => void;
}

const useSocketIO = <T>(userInfo: UserProfile, eventName: string): ReturnType<T> => {
  const [message, setMessage] = useState<T>();
  const [socket, setSocket] = useState<typeof Socket>();

    useEffect(() => {
        const token = Buffer.from(userInfo.username + ':' + userInfo.token).toString('base64')
        const url = backendConfig[Constants.manifest.extra.BACKEND_ENV].WS
        const socket = io(url, {
            path: 'socket.io',
            query: {
                token: token
            },
            transports: ['websocket']
        })
        socket.open()

    socket.on(eventName, (data: T) => {
      setMessage(data);
    });

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, []);

  const sendCommand = (name = eventName, message: any) => {
    if (!socket) return;
    socket.emit(name, message);
  };

  return { message, sendCommand };
};

export default useSocketIO;
