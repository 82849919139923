import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList, ListRenderItem, StyleSheet, Text, View } from 'react-native';
import ReportData from '../api/model/ReportData';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import ListItem from '../elements/ListItem';
import { HasNavigation } from '../Navigation';

interface Props extends HasNavigation {}

const ItemInfo: ListRenderItem<ReportData> = ({ item }: { item: ReportData }) => {
  const date = (item.date && DateTime.fromMillis(item.date!)) || undefined; //TODO: cleanup Date type conversion
  return (
    <ListItem style={styles.item}>
      <View style={styles.item_info}>
        <Text style={styles.item_info_title}>{date?.toFormat('DD') || 'Date not reported'}</Text>
        <Text style={styles.item_info_label}>{item.username}</Text>
        {item.effectValue && <Text style={styles.item_info_label}>{item.effectValue}</Text>}
        {item.effectText && <Text style={styles.item_info_label}>{item.effectText}</Text>}
      </View>
    </ListItem>
  );
};

export default ({ navigation, style }: Props) => {
  const { t } = useTranslation();

  const [items, setItems] = useState<ReportData[]>([]);

  const usersList = async () => {
    const api = await SenopiApi();
    const result = await api.reportList();
    setItems(result);
  };

  useEffect(() => {
    usersList();
  }, []);

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} />
      <View style={[styles.content]}>
        <Text style={[styles.title]}>{t('adverse_reports')}</Text>
        <FlatList
          style={styles.list}
          data={items}
          renderItem={ItemInfo}
          scrollEnabled={true}
          keyExtractor={(item, index) => (Math.random() + index).toString()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  content: {
    paddingStart: 16,
    paddingEnd: 16,
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  list: {
    marginTop: 30,
    // flex: 1
  },

  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  item_info: {
    flexDirection: 'column',
    alignContent: 'flex-start',
  },

  item_info_title: {
    color: '#48A9C5',
    opacity: 0.8,
    fontSize: 16,
    fontFamily: 'SegoeRegular',
  },

  item_info_label: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 20,
    fontFamily: 'SegoeRegular',
  },
});
