import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import { HasNavigation } from '../Navigation';
import { screenHeight } from '../Utils';
import SuperviserCreateUser from './SuperviserCreateUser';
import UsersList from './SuperviserUsersList';

interface Props extends HasNavigation {
  user?: UserProfile;
}

export default ({ user, navigation, dialog, style, ...rest }: Props) => {
  const [users, setUsers] = useState<UserProfile[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    const load = async () => {
      dialog.showLoading();
      const api = await SenopiApi(user);
      const users = await api.users();
      setUsers(users);
      dialog.hideLoading();
    };
    load();
  }, []);

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} />
      <UsersList
        user={user}
        usersFetch={users}
        {...rest}
        dialog={dialog}
        navigation={navigation}
        scrollStyle={styles.user_list}
      />
      <View style={styles.button}>
        <Text
          style={styles.create_user}
          onPress={() =>
            navigation(SuperviserCreateUser, true, {
              user: user,
              size: users.length,
            })
          }
        >
          {'+ ' + t('home_superviser_create_user')}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  create_user: {
    marginTop: 30,
    marginBottom: 30,
    textAlign: 'center',
    color: '#48A9C5',
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
  },

  search: {
    backgroundColor: '#0A100FB2',
    borderRadius: 23,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: 18,
  },

  search_icon: {
    width: 21,
    height: 21,
    marginRight: 23,
  },

  search_input: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    flexGrow: 1,
  },
  button: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 30,
    zIndex: 1000,
  },

  user_list: {
    paddingBottom: 60,
    height: screenHeight * 0.75,
  },
});
