import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    backgroundColor: '#0B020B',
    padding: 10,
    borderRadius: 10,
    width: 345,
    marginVertical: 10,
  },
  title: {
    fontSize: 18,
    color: '#63a7c1',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  difficultyLabel: {
    color: '#9b9c9c',
    marginTop: 18,
    marginLeft: 5,
    fontSize: 15,
    textAlign: 'left',
  },
  labelRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: 20,
    height: 20,
    cursor: 'pointer',
  },
});
