import { FC, useState } from 'react';
import { View } from 'react-native';
import SenopiApi from '../../api/SenopiApi';
import { Dialog } from '../../elements/Dialog';
import HeaderPane from '../../elements/HeaderPane';
import LoginInput from '../../elements/LoginInput';
import SButton from '../../elements/SButton';
import SText from '../../elements/SText';
import { styles } from './styles';
import { ScreenProps } from './types';
import { useTranslation } from 'react-i18next';

const NewPasswordScreen: FC<ScreenProps> = (props) => {
  const { style, navigation, dialog, resetToken } = props;

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordSecure, setPasswordSecure] = useState(true);
  const [isDialogShown, setIsDialogShown] = useState(false);

  const { t } = useTranslation();

  const goBack = () => {
    navigation('..');
  };

  const onReset = async () => {
    if (!email.trim().length || !newPassword.trim().length) return;
    dialog.showLoading();
    try {
      const api = await SenopiApi();
      await api.userReset({ email, newPassword, resetToken });
      setIsDialogShown(true);
    } catch (error) {
      console.error(error);
    }
    dialog.hideLoading();
  };

  return (
    <>
      <View style={[styles.container, style]}>
        <HeaderPane onBackPressed={goBack} style={{ width: '100%' }} />
        <SText style={styles.subtitle}>{t('reset_please_enter_reset')}</SText>
        <View style={styles.form}>
          <LoginInput
            style={{ marginBottom: 35 }}
            onChangeText={setEmail}
            label={t('login_email')}
            textContentType='emailAddress'
            iconWidth={13}
            iconHeight={15}
            value={email}
          />
          <LoginInput
            style={{ marginBottom: 35 }}
            label={t('reset_token')}
            textContentType='none'
            editable={false}
            iconWidth={13}
            iconHeight={15}
            value={resetToken}
          />
          <LoginInput
            onChangeText={setNewPassword}
            label={t('user_password_new')}
            icon={require(passwordSecure
              ? '../../../assets/icon_password.png'
              : '../../../assets/icon_password_open.png')}
            onIconPress={() => setPasswordSecure(!passwordSecure)}
            textContentType='password'
            secureTextEntry={passwordSecure}
            iconWidth={17}
            iconHeight={11}
          />
          <SButton
            disabled={!email}
            style={styles.button}
            label={t('reset_password')}
            onPress={onReset}
          />
        </View>
      </View>

      <Dialog isVisible={isDialogShown} onDismiss={goBack}>
        <SText center style={styles.dialogText}>
          {t('reset_success')}
        </SText>
        <SButton label={t('continue')} onPress={goBack} />
      </Dialog>
    </>
  );
};

export default NewPasswordScreen;
