import { FC, useRef, useState } from 'react';
import { Animated, View, ViewStyle } from 'react-native';
import { Button, ButtonTypesEnum, OpenButton } from './Button';
import { ICON_WIDTH, MAX_WIDTH, OPEN_ANIMATION_DURATION, styles } from './styles';

interface SessionButtonsProps {
  style?: ViewStyle;
  onRepeat: () => void;
  onPause: () => void;
  onPlay: () => void;
  onMute: () => void;
  onSound: () => void;
  onNext: () => void;
  pauseState: boolean;
  muteState: boolean;
}

export const SessionButtons: FC<SessionButtonsProps> = (props) => {
  const { style, onRepeat, onPause, onPlay, onMute, onSound, onNext, pauseState, muteState } =
    props;
  const [isOpen, setIsOpen] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;

  const width = animation.interpolate({ inputRange: [0, 1], outputRange: [60, MAX_WIDTH] });

  const inputRange = [0, 1];

  const translate = animation.interpolate({
    inputRange,
    outputRange: [5, MAX_WIDTH - (ICON_WIDTH + 5)],
  });

  const translate2 = animation.interpolate({
    inputRange,
    outputRange: [5, (ICON_WIDTH + 15) * 1],
  });
  const translate3 = animation.interpolate({
    inputRange,
    outputRange: [5, (ICON_WIDTH + 15) * 2],
  });
  const translate4 = animation.interpolate({
    inputRange,
    outputRange: [5, (ICON_WIDTH + 15) * 3],
  });

  const onOpenOrClose = () => {
    if (isOpen) {
      setTimeout(() => {
        Animated.timing(animation, {
          toValue: 0,
          duration: OPEN_ANIMATION_DURATION,
          useNativeDriver: false,
        }).start();
      }, 1);
    } else {
      setTimeout(() => {
        Animated.timing(animation, {
          toValue: 1,
          duration: OPEN_ANIMATION_DURATION,
          useNativeDriver: false,
        }).start();
      }, 1);
    }
    setIsOpen((prev) => !prev);
  };

  return (
    <View style={[styles.container, { transform: [{ scaleX: -1 }] }, style]}>
      <Animated.View style={[styles.inner, { width: width }]}>
        <View
          style={{
            width: 60,
            height: 60,
            borderRadius: 60,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10000,
          }}
        >
          <OpenButton
            onPress={onOpenOrClose}
            isOpen={isOpen}
            style={[{ position: 'relative' }, isOpen ? { backgroundColor: '#B0B0B0' } : null]}
          />
        </View>
        <Button
          onPress={onNext}
          type={ButtonTypesEnum.Next}
          style={{ zIndex: 1000, transform: [{ translateX: translate2 }] }}
        />
        <Button
          onPress={onPause}
          onPressWithState={onPlay}
          state={pauseState}
          type={ButtonTypesEnum.Pause}
          style={{ zIndex: 100, transform: [{ translateX: translate3 }] }}
        />
        <Button
          onPress={onRepeat}
          type={ButtonTypesEnum.Repeat}
          style={{ zIndex: 10, transform: [{ translateX: translate4 }] }}
        />
        <Button
          onPress={onMute}
          onPressWithState={onSound}
          state={muteState}
          type={ButtonTypesEnum.Sound}
          style={{ zIndex: 1, transform: [{ translateX: translate }] }}
        />
      </Animated.View>
    </View>
  );
};
