import { LinearGradient } from 'expo-linear-gradient';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View } from 'react-native';
import { EnvironmentEnum, MusicEnum } from '../../../../api/model/HeadsetSettings';
import SText from '../../../../elements/SText';
import { styles } from './styles';

const data = {
  [EnvironmentEnum.Beach]: {
    label: 'live_session_environment_beach',
    img: require('../../../../../assets/setup_session/beach.png'),
  },
  [EnvironmentEnum.Forest]: {
    label: 'live_session_environment_forest',
    img: require('../../../../../assets/setup_session/forest.png'),
  },
  [EnvironmentEnum.Mountain]: {
    label: 'live_session_environment_mountain',
    img: require('../../../../../assets/setup_session/mountain.png'),
  },
  [EnvironmentEnum.Sightseeing]: {
    label: 'live_session_environment_urban',
    img: require('../../../../../assets/setup_session/sightseeing.png'),
  },
  [MusicEnum.Jazz]: {
    label: 'live_session_music_jazz',
    img: require('../../../../../assets/setup_session/jazz.png'),
  },
  [MusicEnum.Orchestra]: {
    label: 'live_session_music_orchestra',
    img: require('../../../../../assets/setup_session/orchestra.png'),
  },
  [MusicEnum.Piano]: {
    label: 'live_session_music_piano',
    img: require('../../../../../assets/setup_session/piano.png'),
  },
  [MusicEnum.StringInstruments]: {
    label: 'live_session_music_instruments',
    img: require('../../../../../assets/setup_session/string.png'),
  },
};

interface SelectButtonProps {
  type: keyof typeof data;
  selected: string | null;
  onChange: (type: keyof typeof data | null) => void;
}

export const SelectButton: FC<SelectButtonProps> = (props) => {
  const { type, selected, onChange } = props;
  const { label, img } = data[type];

  const { t } = useTranslation();

  const onChangeHandler = () => {
    if (type === selected) {
      onChange(null);
    } else {
      onChange(type);
    }
  };

  return (
    <TouchableOpacity onPress={onChangeHandler}>
      <LinearGradient
        style={styles.container}
        colors={['#D4F8FF', '#0F4656']}
        start={{ x: 0.1, y: 0.1 }}
        end={{ x: 1, y: 1 }}
      >
        <LinearGradient
          style={styles.inner}
          colors={['#49D3FB', '#9CE4F8']}
          start={{ x: 0.1, y: 0.1 }}
          end={{ x: 1, y: 1 }}
        >
          <Image source={img} style={{ width: 100, height: 100 }} />
          <SText style={styles.label}>{t(label)}</SText>
        </LinearGradient>
        {selected === type && (
          <View style={styles.select}>
            <Image
              source={require('../../../../../assets/setup_session/checkmark.png')}
              style={styles.check}
            />
          </View>
        )}
      </LinearGradient>
    </TouchableOpacity>
  );
};
