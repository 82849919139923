import { FC } from 'react';
import { Dialog } from '../Dialog';
import SButton from '../SButton';
import SText from '../SText';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

interface DialogInfoProps {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  message: string;
  onPress?: () => void;
}
export const DialogInfo: FC<DialogInfoProps> = (props) => {
  const { isVisible: isVisible, setIsVisible: setIsVisible, message, onPress } = props;

  const { t } = useTranslation();
  const onPressHandler = () => {
    setIsVisible(false);
    if (onPress) {
      onPress();
    }
  };
  return (
    <Dialog isVisible={isVisible} onDismiss={() => setIsVisible(false)}>
      <SText style={styles.info_title}>{message}</SText>
      <SButton style={{ width: '100%' }} label={t('continue')} onPress={onPressHandler} />
    </Dialog>
  );
};
