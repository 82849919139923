import { ActivityIndicator } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import { HasNavigation } from '../Navigation';
import SubSelectUserScreen from './SubSelectUserScreen';

interface SelectVrUserProps extends HasNavigation {
  headset?: { headset: UserProfile; user?: UserProfile };
}

export default ({ headset, dialog, navigation, style, ...rest }: SelectVrUserProps) => {
  const onSelectVrUser = async (user: UserProfile) => {
    const api = await SenopiApi();
    dialog.showDialog(<ActivityIndicator color={'#FFFFFF'} />, { backgroundColor: '' });
    await api.assignHeadset(headset?.headset.username || '', user?.username || '');
    dialog.hideDialog();
    navigation('..');
  };

  return (
    <SubSelectUserScreen
      style={style}
      source='subadmin'
      dialog={dialog}
      navigation={navigation}
      onSelectUser={onSelectVrUser}
      {...rest}
    />
  );
};
