import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View } from 'react-native';
import { ExerciseEnum, HeadsetSettingsModule } from '../../../../api/model/HeadsetSettings';
import DropDown from '../../../../elements/DropDown';
import SButton from '../../../../elements/SButton';
import SText from '../../../../elements/SText';
import { styles } from './styles';

interface ExerciseProps {
  length: number;
  module: HeadsetSettingsModule;
  i: number;
  selectHandler: (value: HeadsetSettingsModule, i: number) => void;
  isExpanded: boolean;
  onExpanded: (id: string) => void;
  onRemove: (id: number) => void;
}

export const Exercise: FC<ExerciseProps> = (props) => {
  const { length, module, i, selectHandler, isExpanded, onExpanded, onRemove } = props;

  const { t } = useTranslation();

  const onChangeName = (value: ExerciseEnum) => {
    selectHandler({ ...module, name: value }, i);
  };

  const onChangeDuration = (value: number) => {
    selectHandler({ ...module, duration: value }, i);
  };

  const onChangeLevel = (value: number) => {
    selectHandler({ ...module, level: value }, i);
  };

  return (
    <View style={[styles.container, { zIndex: (length - i) * 2 }]}>
      <View style={styles.labelRow}>
        <SText style={styles.title}>{t('live_session_exercise_title') + ' ' + (i + 1)}</SText>
        {i !== 0 && (
          <TouchableOpacity onPress={() => onRemove(i)}>
            <Image
              style={styles.icon}
              source={require('../../../../../assets/icon_minus_sign.png')}
            />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.row}>
        <DropDown
          style={{ width: '57%' }}
          placeholderText={t('live_session_setup_exercise_none')}
          zIndex={(length - i) * 2 + 10}
          zIndexInverse={2}
          value={module.name}
          items={[
            { label: t('profile_main_physical'), value: ExerciseEnum.Warmup },
            { label: t('profile_main_focus'), value: ExerciseEnum.Focus },
            { label: t('profile_main_switch'), value: ExerciseEnum.Switch },
            { label: t('profile_main_memory'), value: ExerciseEnum.Memory },
          ]}
          onValueChange={({ value }) => onChangeName(value)}
          dropDownContainerStyle={{ backgroundColor: '#0B020B' }}
        />
        <DropDown
          style={{ width: '40%' }}
          placeholderText={t('live_session_setup_select')}
          zIndex={(length - i) * 2 + 5}
          zIndexInverse={1}
          value={module.duration}
          items={[
            { label: '1 ' + t('live_session_setup_minutes'), value: (1 * 60) },
            { label: '2 ' + t('live_session_setup_minutes'), value: (2 * 60) },
            { label: '3 ' + t('live_session_setup_minutes'), value: (3 * 60) },
            { label: '4 ' + t('live_session_setup_minutes'), value: (4 * 60) },
            { label: '5 ' + t('live_session_setup_minutes'), value: (5 * 60) },
            { label: '6 ' + t('live_session_setup_minutes'), value: (6 * 60) },
            { label: '8 ' + t('live_session_setup_minutes'), value: (8 * 60) },
            { label: '10 ' + t('live_session_setup_minutes'), value: (10 * 60) },
            { label: '12 ' + t('live_session_setup_minutes'), value: (12 * 60) },
          ]}
          onValueChange={({ value }) => onChangeDuration(value)}
          dropDownContainerStyle={{ backgroundColor: '#0B020B' }}
        />
      </View>
      <SButton
        label={
          isExpanded
            ? t('live_session_setup_difficulty_level')
            : `+ ${t('live_session_setup_advanced_options')}`
        }
        type='text'
        labelStyle={styles.difficultyLabel}
        style={{ zIndex: -1 }}
        onPress={() => onExpanded(i.toString())}
      ></SButton>
      {isExpanded && (
        <DropDown
          style={{ width: '67%', marginBottom: 20 }}
          placeholderText={t('live_session_setup_select')}
          zIndex={-1}
          zIndexInverse={0}
          value={module.level}
          items={[
            { label: t('live_session_setup_level_automatic'), value: 0 },
            { label: t('live_session_setup_level_very_easy'), value: 1 },
            { label: t('live_session_setup_level_easy'), value: 11 },
            { label: t('live_session_setup_level_medium'), value: 21 },
            { label: t('live_session_setup_level_hard'), value: 31 },
            { label: t('live_session_setup_level_very_hard'), value: 41 },
          ]}
          onValueChange={({ value }) => onChangeLevel(value)}
          dropDownContainerStyle={{ backgroundColor: '#0B020B' }}
        />
      )}
    </View>
  );
};
