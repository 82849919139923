import { createSlice } from '@reduxjs/toolkit';
import {
  EnvironmentEnum,
  ExerciseEnum,
  HeadsetSettingsModule,
  MusicEnum,
} from '../../api/model/HeadsetSettings';
import UserProfile from '../../api/model/UserProfile';

interface InitialState {
  sessionSettings: {
    environment: EnvironmentEnum | null;
    music: MusicEnum | null;
    modules: HeadsetSettingsModule[];
  };
  user: UserProfile | null;
  currentScreen: number;
  isOldVersion: boolean;
}

const initialState: InitialState = {
  sessionSettings: {
    environment: null,
    music: null,
    modules: [],
  },
  user: null,
  currentScreen: 0,
  isOldVersion: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionSettingsAction(state, action) {
      const { payload } = action;
      state.sessionSettings = payload;
    },
    setSessionUserAction(state, action) {
      const { payload } = action;
      state.user = payload;
    },
    setSessionCurrentScreenAction(state, action) {
      const { payload } = action;
      state.currentScreen = payload;
    },
    setIsOldVersionAction(state, action) {
      const { payload } = action;
      state.isOldVersion = payload;
    },
  },
});

export const {
  setSessionSettingsAction,
  setSessionUserAction,
  setSessionCurrentScreenAction,
  setIsOldVersionAction,
} = sessionSlice.actions;

export const sessionReducer = sessionSlice.reducer;
