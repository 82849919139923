import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { rootReducer } from './reducers';

export const getStore = () => {
  const store = configureStore({
    reducer: combineReducers(rootReducer),
  });

  return {
    store,
  };
};

export type StoreType = ReturnType<typeof getStore>['store'];
export type RootState = ReturnType<StoreType['getState']>;
export type AppDispatch = StoreType['dispatch'];
