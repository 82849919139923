import { RootState } from '..';

export const getSessionSettingsSelector = (state: RootState) =>
  state.sessionReducer.sessionSettings;

export const getSessionUserSelector = (state: RootState) => state.sessionReducer.user;

export const getSessionCurrentScreenSelector = (state: RootState) =>
  state.sessionReducer.currentScreen;

export const getIsOldVersionSelector = (state: RootState) => state.sessionReducer.isOldVersion;
