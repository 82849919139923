import { FC } from 'react';
import { View } from 'react-native';
import { MusicEnum } from '../../../../api/model/HeadsetSettings';
import SText from '../../../../elements/SText';
import { SelectButton } from '../SelectButton';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';

interface MusicProps {
  value: string | null;
  onChange: (value: string) => void;
}

export const Music: FC<MusicProps> = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();
  return (
    <>
      <SText style={styles.title_main}>{t('live_session_setup_session')}</SText>
      <SText style={styles.title}>{t('live_session_setup_select_music')}</SText>
      <View style={styles.container}>
        <View style={styles.row}>
          <SelectButton type={MusicEnum.StringInstruments} selected={value} onChange={onChange} />
          <SelectButton type={MusicEnum.Jazz} selected={value} onChange={onChange} />
        </View>
        <View style={styles.row}>
          <SelectButton type={MusicEnum.Piano} selected={value} onChange={onChange} />
          <SelectButton type={MusicEnum.Orchestra} selected={value} onChange={onChange} />
        </View>
      </View>
    </>
  );
};
