import { useLoader } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import { TextureLoader } from 'expo-three';
import {
  balloonBlue,
  balloonGreen,
  balloonOrange,
  balloonPink,
  balloonYellow,
  heartBlue,
  heartGreen,
  heartOrange,
  heartPink,
  heartYellow,
  starGreen,
  starOrange,
  starPink,
  starYellow,
  moonBlue,
  moonGreen,
  moonOrange,
  moonPink,
  moonYellow,
  candyBlue,
  candyGreen,
  candyOrange,
  candyPink,
  candyYellow,
  starBlue,
} from '../assets/balloons';

const assets: Record<string, string> = {
  orange: balloonOrange,
  blue: balloonBlue,
  green: balloonGreen,
  pink: balloonPink,
  yellow: balloonYellow,
  orange_candy: candyOrange,
  blue_candy: candyBlue,
  green_candy: candyGreen,
  yellow_candy: candyYellow,
  pink_candy: candyPink,
  orange_heart: heartOrange,
  blue_heart: heartBlue,
  green_heart: heartGreen,
  pink_heart: heartPink,
  yellow_heart: heartYellow,
  orange_moon: moonOrange,
  blue_moon: moonBlue,
  green_moon: moonGreen,
  pink_moon: moonPink,
  yellow_moon: moonYellow,
  orange_star: starOrange,
  green_star: starGreen,
  pink_star: starPink,
  yellow_star: starYellow,
  blue_star: starBlue,
};

export enum TargetEnum {
  Left = 'left',
  Right = 'right',
  No = 'no',
}

export interface BallonInterface {
  asset: string;
  screenLocationX: number;
  screenLocationY: number;
  target: TargetEnum;
  interStimulusInterval: number;
  speed: number;
}

export interface Position {
  x: number;
  y: number;
  z: number;
}

export interface BalloonProps {
  initialPosition: Position;
  asset: string;
  speed: number;
}
const getAssetImage = (asset: string) => {
  const image = assets[asset] || balloonBlue;
  return image;
};

export const Balloon = ({ initialPosition, asset }: BalloonProps) => {
  const { x, y, z } = initialPosition;
  const [showBalloon, setShowBalloon] = useState<boolean>(true);
  const myMesh = React.useRef();
  // @ts-ignore
  const texture = useLoader(TextureLoader, getAssetImage(asset));

  useEffect(() => {
    if (z === 0 && showBalloon === true) {
      setShowBalloon(false);
    } else if (z !== 0 && showBalloon === false) {
      setShowBalloon(true);
    }
  }, [z]);
  console.log('Balloon', initialPosition, asset);
  const scale = 4.2;
  return showBalloon ? (
    <mesh position={[x, y, -z]} ref={myMesh} scale={scale}>
      <planeGeometry attach='geometry' args={[1.5, 4]} />
      <meshBasicMaterial attach={'material'} map={texture} transparent />
    </mesh>
  ) : null;
};
