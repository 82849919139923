import { StyleSheet } from 'react-native';

export const MAX_WIDTH = 345;
export const ICON_WIDTH = 50;
export const OPEN_ANIMATION_DURATION = 150;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 60,
    maxWidth: MAX_WIDTH,
    position: 'relative',
    justifyContent: 'center',
  },
  inner: {
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 50,
    height: '100%',
  },
});
