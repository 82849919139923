import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { HasNavigation } from '../Navigation';
import SenopiApi from '../api/SenopiApi';
import UserProfile from '../api/model/UserProfile';
import HeaderPane from '../elements/HeaderPane';
import SButton from '../elements/SButton';
import SectionView from '../elements/SectionView';
import TitledInput from '../elements/TitledInput';
import SuperviserEditUser from './SuperviserEditUser';

interface Props extends HasNavigation {
  user?: UserProfile;
  size: number;
  usersPrepared?: UserProfile[];
}

const HeaderRight = ({ onInfoPress }: { onInfoPress: () => void }) => (
  <View style={{ flexDirection: 'row' }}>
    <TouchableOpacity style={{ padding: 14 }} onPress={onInfoPress}>
      <Image
        source={require('../../assets/icon_user_info.png')}
        style={{ width: 20, height: 20 }}
      />
    </TouchableOpacity>
  </View>
);

const NewUserItem = ({
  user,
  onChangeText,
  onMorePress,
}: {
  user: UserProfile;
  onChangeText: (value: any, field: any) => void;
  onMorePress: (value: UserProfile) => void;
}) => {
  const { t } = useTranslation();
  return (
    <View>
      <TitledInput
        style={{ marginTop: 20 }}
        value={user.privateData.username}
        label={t('superviser_user_create_user_username')}
        onChangeText={(value) => onChangeText(value, 'username')}
      />
      <TitledInput
        style={{ marginTop: 20 }}
        value={user.privateData.email}
        label={t('superviser_user_create_user_email')}
        onChangeText={(value) => onChangeText(value, 'email')}
      />
      <TitledInput
        style={{ marginTop: 20 }}
        label={t('superviser_user_create_user_phone')}
        keyboardType='number-pad'
        value={user.privateData?.phone || ''}
        onChangeText={(value) => onChangeText(value, 'phone')}
      />
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
        <Text style={styles.usercode_title}>
          {t('superviser_user_create_user_usercode')}
          <Text style={{ color: '#868686' }}>{user.username}</Text>
        </Text>
        <Text style={styles.more_details} onPress={() => onMorePress(user)}>
          {'+ ' + t('superviser_create_user_more_details')}
        </Text>
      </View>
    </View>
  );
};

export default ({ navigation, style, size, dialog, user, usersPrepared }: Props) => {
  const { t } = useTranslation();
  const { top } = useSafeAreaInsets();
  const [infoVisible, setInfoVisible] = useState(false);
  const [usersList, setUsersList] = useState<UserProfile[]>(
    (usersPrepared && [...usersPrepared]) || [
      new UserProfile('user', user?.username + '-' + (size + 1).toString().padStart(3, '0')),
    ],
  );

  const addMorePress = (): void => {
    setUsersList([
      ...usersList,
      new UserProfile(
        'user',
        user?.username + '-' + (size + 1 + usersList.length).toString().padStart(3, '0'),
      ),
    ]);
  };

  const onSaveUsers = async () => {
    dialog.showLoading();
    const api = await SenopiApi(user);
    await api.usersCreateMany(usersList);
    dialog.hideLoading();
    navigation('..');
  };

  const prepareItem = (form: UserProfile) => {
    usersList
      .filter((item: UserProfile) => item.username == form.username)
      .map((item: UserProfile) => Object.assign(item, form));
    navigation('..', false, { usersPrepared: usersList });
  };

  return (
    <View style={[styles.container, style]}>
      <HeaderPane
        onBackPressed={() => navigation('..')}
        rightComponent={<HeaderRight onInfoPress={() => setInfoVisible((prev) => !prev)} />}
      />
      <Text style={styles.title}>{t('superviser_user_create_title')}</Text>
      <ScrollView style={styles.scroll} contentContainerStyle={styles.scroll_content}>
        {usersList.map((value, index) => {
          return (
            <NewUserItem
              key={`user-${index}`}
              user={value}
              onChangeText={(val, field) => {
                //@ts-ignore
                value.privateData[field] = val;
                setUsersList([...usersList]);
              }}
              onMorePress={(val) =>
                navigation(SuperviserEditUser, true, {
                  user: val,
                  isEdit: false,
                  onBack: () => navigation('..', false, { usersPrepared: usersList }),
                  onSave: prepareItem,
                })
              }
            />
          );
        })}
        <Text style={styles.add_more} onPress={addMorePress}>
          {'+ ' + t('superviser_create_user_add_more')}
        </Text>
      </ScrollView>
      <SButton
        disabled={usersList.filter(u => (u.privateData.username?.trim()?.length || 0) == 0).length != 0}
        style={styles.save_button}
        label={t('superviser_user_create_save')}
        
        onPress={onSaveUsers}
      />
      {infoVisible && (
        <View
          style={[
            styles.container,
            { position: 'absolute', top: top + 50, backgroundColor: '#00000050' },
          ]}
        >
          <SectionView style={{ alignItems: 'center', paddingVertical: 20 }}>
            <Text style={styles.info_title}>{t('superviser_user_create_info_title')}</Text>
            <Text style={styles.info_body}>{t('superviser_user_create_info_body')}</Text>
          </SectionView>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
    marginTop: 20,
  },

  scroll: {
    flex: 1,
    flexGrow: 1,
  },

  scroll_content: {
    paddingHorizontal: 16,
  },

  save_button: {
    width: 256,
    marginVertical: 20,
    alignSelf: 'center',
  },

  add_more: {
    marginTop: 30,
    textAlign: 'center',
    color: '#48A9C5',
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
  },

  more_details: {
    textAlign: 'right',
    color: '#48A9C5',
    fontSize: 18,
    lineHeight: 22,
    fontFamily: 'SegoeRegular',
  },

  usercode_title: {
    color: '#FFFFFF',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'left',
    fontFamily: 'SegoeRegular',
  },

  info_title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
    marginBottom: 10,
  },
  info_body: {
    color: '#868686',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
  },
});
