import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import LoginInput from '../elements/LoginInput';
import SButton from '../elements/SButton';
import { HasNavigation } from '../Navigation';
interface Props extends HasNavigation {
  user?: UserProfile;
}

export default ({ user, dialog, navigation, style, ...rest }: Props) => {
  const { t } = useTranslation();

  const [headsetName, setHeadsetName] = useState(user?.firstName || '');

  const onSave = async () => {
    try {
      const api = await SenopiApi();
      dialog.showDialog(<ActivityIndicator color={'#FFFFFF'} />, { backgroundColor: '' });

      if (user?.type == 'headset') {
        await api.updateHeadset(user!.username, {
          firstName: headsetName,
          lastName: undefined,
        });
      }
      dialog.hideDialog();
      navigation('..');
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const roles =
    user?.type == 'subadmin'
      ? new Map([['subadmin', t('user_role_subadmin')]])
      : new Map([
          ['user', t('user_role_user')],
          ['placebo', t('user_role_placebo')],
        ]);

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} style={{ width: '100%' }} />
      <Text style={[styles.title]}>{t('headset_title')}</Text>
      <View style={styles.form}>
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setHeadsetName}
          value={headsetName}
          label={t('headset_name')}
        />

        <View style={{ flexGrow: 1 }} />
        <SButton style={styles.login_button} label={t('create_submit')} onPress={onSave} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 20,
  },

  form: {
    width: 314,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 40,
  },

  login_button: {
    width: 256,
    marginTop: 31,
    marginBottom: 11,
  },

  label_style: {
    color: '#FFFFFF',
    fontSize: 18,
    fontFamily: 'SegoeRegular',
    opacity: 0.8,
  },
});
