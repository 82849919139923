import { StyleSheet } from 'react-native';
import { ICON_WIDTH } from '../styles';

export const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: ICON_WIDTH,
    height: ICON_WIDTH,
    borderRadius: ICON_WIDTH,
    backgroundColor: '#48A9C5',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inner: {
    height: '100%',
    width: '100%',
    transform: [{ scaleX: -1 }],
    alignItems: 'center',
    justifyContent: 'center',
  },
});
