import { FC, useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { Calendar, DateData, LocaleConfig } from 'react-native-calendars';
import { DayProps } from 'react-native-calendars/src/calendar/day';
import { Direction, MarkedDates } from 'react-native-calendars/src/types';

LocaleConfig.locales['en'] = {
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  today: 'Today',
};
LocaleConfig.defaultLocale = 'en';

interface Props {
  datesToMark?: string[];
  badges?: {
    [key: string]: number;
  };
  onDateClick?: (date: string | undefined) => void;
  date: string;
}

const dateStyles = {
  today: {
    customStyles: {
      container: {
        borderRadius: 0,
        borderBottomColor: '#48A9C5',
        borderBottomWidth: 2,
      },
    },
  },
  selected: {
    customStyles: {
      container: {
        backgroundColor: '#FFFFFF',
      },
      text: {
        color: '#48A9C5',
      },
    },
  },
  marked: {
    customStyles: {
      container: {
        backgroundColor: '#48A9C5',
      },
    },
  },
};

const InfoCalendar: React.FC<Props> = ({ datesToMark, onDateClick, badges, date }: Props) => {
  const todayDate = new Date().toISOString().slice(0, 10);

  const [selectedDate, setSelectedDate] = useState<string | undefined>(date || undefined);
  const [markedDates, setMarkedDates] = useState<MarkedDates>({});

  const markDates = (prevMarkedDates: MarkedDates): MarkedDates => {
    prevMarkedDates[todayDate] = dateStyles.today;
    datesToMark?.forEach((date) => {
      prevMarkedDates[date] = dateStyles.marked;
    });

    return prevMarkedDates;
  };

  useEffect(() => {
    const newMarkedDates: MarkedDates = {};
    newMarkedDates[todayDate] = dateStyles.today;
    datesToMark?.forEach((date) => {
      newMarkedDates[date] = dateStyles.marked;
    });
    setMarkedDates(newMarkedDates);
  }, [datesToMark]);

  const onDatePress = (date: DateData) => {
    const dateToSet = selectedDate === date.dateString ? undefined : date.dateString;

    if (dateToSet) {
      setMarkedDates((prev) => {
        if (selectedDate !== undefined) {
          delete prev[selectedDate];
        }

        return {
          ...markDates(prev),
          [dateToSet]: dateStyles.selected,
        };
      });
    } else {
      setMarkedDates((prev) => {
        delete prev[selectedDate as string];

        return markDates(prev);
      });
    }
    setSelectedDate(dateToSet);
    onDateClick?.(dateToSet);
  };

  useEffect(() => {
    setMarkedDates((prev) => {
      if (date !== undefined) {
        delete prev[date];
      }

      return {
        ...markDates(prev),
        [date]: dateStyles.selected,
      };
    });
  }, [date]);

  return (
    <Calendar
      style={{ opacity: 1 }}
      theme={{
        backgroundColor: 'transparent',
        calendarBackground: 'transparent',
        arrowColor: '#48A9C5',
        textDayFontFamily: 'SegoeRegular',
        textMonthFontFamily: 'SegoeRegular',
        textDayHeaderFontFamily: 'SegoeRegular',
        textMonthFontSize: 17,
        monthTextColor: '#48A9C5',
        textDayHeaderFontSize: 20,
        textDayFontSize: 20,
        dayTextColor: '#FFFFFF',
        todayTextColor: '#FFFFFF',
        textDisabledColor: '#868686',
        // @ts-ignore
        'stylesheet.calendar.header': {
          week: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: '#707070',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: '3.5%',
          },
        },
      }}
      firstDay={1}
      markingType='custom'
      markedDates={markedDates}
      onDayPress={onDatePress}
      dayComponent={(data) => (
        <Day {...data} badge={badges ? badges[data?.date?.dateString || ''] : 0} />
      )}
      renderArrow={(direction) => <Arrow direction={direction} />}
      selected={date}
    />
  );
};

const Day: React.FC<
  DayProps & {
    date?: DateData | undefined;
    badge?: number | undefined;
  }
> = (props) => {
  const { date, theme, onPress, onLongPress, state, marking, badge } = props;
  return (
    <TouchableOpacity
      style={[styles.container, marking?.customStyles?.container]}
      onPress={() => onPress?.(date)}
      onLongPress={() => onLongPress?.(date)}
    >
      {!!badge && (
        <View style={[styles.badge, { backgroundColor: '#fff' }]}>
          <Text style={[styles.badgeText, { color: '#48A9C5' }]}>{badge}</Text>
        </View>
      )}
      <Text
        style={[
          styles.text,
          {
            color: theme?.dayTextColor,
            fontFamily: theme?.textDayFontFamily,
            fontSize: theme?.textDayFontSize,
            opacity: state === 'disabled' ? 0.4 : 1,
          },
          marking?.customStyles?.text,
        ]}
      >
        {date?.day}
      </Text>
    </TouchableOpacity>
  );
};

interface Arrow {
  direction: Direction;
}

const Arrow: FC<Arrow> = (props) => {
  const { direction } = props;
  return (
    <View
      style={[styles.arrow, { transform: [{ rotate: direction === 'left' ? '-90deg' : '90deg' }] }]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    width: 35,
    height: 35,
    borderRadius: 35,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: '#fff',
    fontSize: 20,
    fontWeight: '400',
  },
  badge: {
    position: 'absolute',
    width: 13,
    height: 13,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    right: -2,
    top: -2,
  },
  badgeText: {
    fontSize: 10,
  },

  arrow: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderBottomWidth: 13,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: '#FFF',
  },
});

export default InfoCalendar;
