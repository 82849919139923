import { useTranslation } from 'react-i18next';
import { Image, ImageSourcePropType, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { MAX_SCREEN_WIDTH } from '../../App';
import {
  capitalizeFirst,
  getUsername,
  screenWidth,
  secondsToMinutesSeconds,
  usernameFirstLetter,
} from '../../Utils';
import { SocketEvent } from '../../api/Socket';
import { ExerciseEnum } from '../../api/model/HeadsetSettings';
import {
  ExerciseDetails,
  ExerciseInfo,
  PerformanceInfo,
  Screen,
} from '../../api/model/LiveSession';
import UserProfile from '../../api/model/UserProfile';
import SText from '../../elements/SText';
import SectionView from '../../elements/SectionView';
import { SessionButtons } from '../../elements/SessionButtons';
import {
  getIsOldVersionSelector,
  getSessionSettingsSelector,
} from '../../redux/selectors/session.selector';
import { useState } from 'react';

interface UserInfoProps {
  user: UserProfile;
  sendCommand: (data: string, payload?: any) => void;
  currentExercise: ExerciseInfo;
}

const UserInfo: React.FC<UserInfoProps> = ({
  user,
  sendCommand,
  currentExercise,
}: UserInfoProps) => {
  const isOldVersion = false;//useSelector(getIsOldVersionSelector);
  const userFullName = getUsername(user);
  const nameLetter = usernameFirstLetter(user);
  const headsetLabel = currentExercise.headsetId;

  const maxWidth = screenWidth > MAX_SCREEN_WIDTH ? MAX_SCREEN_WIDTH : screenWidth;

  const onPause = () => {
    sendCommand(SocketEvent.ControlPause, true);
  };
  const onNext = () => {
    sendCommand(SocketEvent.ControlNext);
  };
  const onPlay = () => {
    sendCommand(SocketEvent.ControlPause, false);
  };

  const onMute = () => {
    sendCommand(SocketEvent.ControlMute, true);
  };

  const onSound = () => {
    sendCommand(SocketEvent.ControlMute, false);
  };

  const onRepeat = () => {
    sendCommand(SocketEvent.ControlRestart);
  };

  return (
    <>
      <SectionView
        style={
          currentExercise.screen === Screen.GAME && !isOldVersion ? { width: maxWidth - 96 } : null
        }
      >
        <View style={styles.user_info}>
          <View style={styles.username_view_container}>
            <Text style={styles.username_view_title}>{nameLetter}</Text>
          </View>
          <View style={styles.headset_info_subsection}>
            <Text style={styles.section_title}>{userFullName}</Text>
            <Text style={styles.secondary_text}>{headsetLabel}</Text>
          </View>
        </View>
      </SectionView>
      {currentExercise.screen === Screen.GAME && !isOldVersion && (
        <SessionButtons
          onPause={onPause}
          onNext={onNext}
          onPlay={onPlay}
          onMute={onMute}
          onSound={onSound}
          onRepeat={onRepeat}
          muteState={currentExercise.controlMuted}
          pauseState={currentExercise.controlPaused}
          style={styles.control_buttons}
        />
      )}
    </>
  );
};

interface AdditionalInstructionsProps {
  screen: Screen | null;
}

const AdditionalInstructions: React.FC<AdditionalInstructionsProps> = ({
  screen,
}: AdditionalInstructionsProps) => {
  const { t } = useTranslation();
  switch (screen) {
    case Screen.MUSIC_SELECTION:
    case Screen.ENVIRONMENT:
      return (
        <SectionView style={styles.additional_instructions}>
          <Text style={styles.instructions_text}>
            {t('live_session_additional_instructions_music')}
          </Text>
        </SectionView>
      );
    case Screen.WELCOME:
    case Screen.OVERVIEW:
    case Screen.INSTRUCTIONS:
    case Screen.FINAL_OVERVIEW:
      return (
        <SectionView style={styles.additional_instructions}>
          <Text style={styles.instructions_text}>{t('live_session_additional_instructions')}</Text>
        </SectionView>
      );
    default:
      return null;
  }
};

interface BalloonsImageProps {
  screen: Screen | null;
}

const BalloonsImage: React.FC<BalloonsImageProps> = ({ screen }: BalloonsImageProps) => {
  switch (screen) {
    case Screen.TUTORIAL:
      return (
        <Image
          style={styles.balloons_image}
          source={require('../../../assets/live_session/tutorial_balloons.png')}
          resizeMode='contain'
        />
      );
    default:
      return null;
  }
};

interface ExerciseInfoViewProps {
  exercise: ExerciseInfo;
}

const ExerciseInfoView: React.FC<ExerciseInfoViewProps> = ({ exercise }: ExerciseInfoViewProps) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <View style={styles.row}>
        <SectionView style={{ flex: 2 }}>
          <Text style={styles.section_title}>{t('live_session_exercise_title')}</Text>
          <Text style={styles.section_body}>
            {exercise.exerciseInfo?.exerciseNameLang[i18n.language.toUpperCase()] || ''}
          </Text>
        </SectionView>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_time_title')}</Text>
          <Text style={styles.section_body}>
            {secondsToMinutesSeconds(exercise.performanceInfo?.timeLeft || 0)}
          </Text>
        </SectionView>
      </View>
      <View style={styles.row}>
        <SectionView style={{ flex: 2 }}>
          <Text style={styles.section_title}>{t('live_session_instructions_title')}</Text>
          <Text style={styles.instructions_text}>
            {exercise.exerciseInfo?.instructionsLang[i18n.language.toUpperCase()] || ''}
          </Text>
        </SectionView>
      </View>
      <View style={styles.row}>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_correct_title')}</Text>
          <Text style={styles.section_body}>{exercise.performanceInfo?.correct || 0}</Text>
        </SectionView>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_incorrect_title')}</Text>
          <Text style={styles.section_body}>{exercise.performanceInfo?.incorrect || 0}</Text>
        </SectionView>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_misses_title')}</Text>
          <Text style={styles.section_body}>{exercise.performanceInfo?.misses || 0}</Text>
        </SectionView>
      </View>
    </>
  );
};

interface CalibrationViewProps {
  step: number;
}

const CalibrationView: React.FC<CalibrationViewProps> = ({ step }: CalibrationViewProps) => {
  const { t } = useTranslation();
  const params: Record<number, { text: string; image: ImageSourcePropType }> = {
    1: {
      text: t('live_session_calibration_body_first'),
      image: require('../../../assets/live_session/calibration_screen_1.png'),
    },
    2: {
      text: t('live_session_calibration_body_second'),
      image: require('../../../assets/live_session/calibration_screen_2.png'),
    },
  };

  return (
    <View style={styles.grow_column}>
      <SectionView>
        <Text style={styles.section_title}>{t('live_session_calibration_screen')}</Text>
        <Text style={styles.instructions_text}>{params[step].text}</Text>
      </SectionView>
      <View style={styles.calibration_image_container}>
        <Image style={styles.calibration_image} source={params[step].image} />
      </View>
    </View>
  );
};

const WelcomeView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionView>
      <Text style={styles.section_title}>{t('live_session_welcome_screen')}</Text>
      <Text style={styles.instructions_text}>{t('live_session_welcome_body')}</Text>
    </SectionView>
  );
};

const SessionOverviewView = () => {
  const settings = useSelector(getSessionSettingsSelector);
  const isOldVersion = useSelector(getIsOldVersionSelector);

  const { t } = useTranslation();

  const getTranslation = (name: ExerciseEnum): string => {
    switch (name) {
      case ExerciseEnum.Focus:
        return t('live_session_overview_focus');
      case ExerciseEnum.Memory:
        return t('live_session_overview_memory');
      case ExerciseEnum.Switch:
        return t('live_session_overview_switch');
      case ExerciseEnum.Warmup:
        return t('live_session_overview_warmup');
      default:
        return '';
    }
  };

  const data = isOldVersion
    ? [
        {
          label: t('live_session_overview_warmup'),
          minute: 3,
        },
        {
          label: t('live_session_overview_focus'),
          minute: 3,
        },
        {
          label: t('live_session_overview_switch'),
          minute: 3,
        },
        {
          label: t('live_session_overview_memory'),
          minute: 3,
        },
      ]
    : settings.modules.map((el) => ({
        label: getTranslation(el.name),
        minute: el.duration / 60,
      }));

  return (
    <>
      <SectionView>
        <SText style={styles.section_title}>{t('live_session_overview_screen')}</SText>
        <SText style={styles.instructions_text}>{t('live_session_overview_body')}</SText>
      </SectionView>
      <SectionView>
        <SText style={styles.session_overview_title}>{t('live_session_overview_title')}</SText>
        <View style={styles.session_overview_body}>
          {data.map(({ label, minute }, index) => (
            <View key={index.toString()} style={styles.session_overview_row}>
              <SText style={styles.session_overview_row_text}>{label}</SText>
              <SText style={styles.session_overview_row_text}>{`${minute} min`}</SText>
            </View>
          ))}
          <View style={[styles.session_overview_row, styles.session_overview_dotted]}>
            <SText style={styles.session_overview_row_text}>
              {t('live_session_overview_total_time')}
            </SText>
            <SText style={styles.session_overview_row_text}>{`${data.reduce(
              (a, b) => (a += b.minute),
              0,
            )} min`}</SText>
          </View>
        </View>
      </SectionView>
    </>
  );
};

interface InstructionsViewProps {
  exerciseInfo: ExerciseDetails;
}

const InstructionsView: React.FC<InstructionsViewProps> = ({
  exerciseInfo,
}: InstructionsViewProps) => {
  const { t, i18n } = useTranslation();
  const currentLangKey = i18n.language.toUpperCase() as keyof typeof exerciseInfo.exerciseNameLang;
  const title = t('live_session_instructions_screen').replace(
    '{name}',
    exerciseInfo?.exerciseNameLang[currentLangKey],
  ); // Do normal replace
  const instructions = exerciseInfo.instructionsLang[currentLangKey];
  const showAdditionalContent = exerciseInfo.exerciseName === 'Memory';

  return (
    <>
      <SectionView>
        <Text style={styles.section_title}>{title}</Text>
        <Text style={styles.instructions_text}>{instructions}</Text>
      </SectionView>
      {showAdditionalContent ? (
        <View style={[styles.row, {}]}>
          <SelectionItem text={t('live_session_instructions_start_tutorial')} />
          <SelectionItem text={t('live_session_instructions_to_exercise')} />
        </View>
      ) : null}
    </>
  );
};

const GoodbyeView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionView>
      <Text style={styles.section_title}>{t('live_session_goodbye_screen')}</Text>
      <Text style={styles.instructions_text}>{t('live_session_goodbye_body')}</Text>
    </SectionView>
  );
};

interface ResultsViewProps {
  performanceInfo: PerformanceInfo | undefined;
}

const ResultsView: React.FC<ResultsViewProps> = ({ performanceInfo }: ResultsViewProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionView>
        <Text style={styles.section_title}>{t('live_session_results_screen')}</Text>
        <Text style={styles.instructions_text}>{t('live_session_results_body')}</Text>
      </SectionView>
      <View style={styles.row}>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_correct_title')}</Text>
          <Text style={styles.section_body}>{performanceInfo?.correct || 0}</Text>
        </SectionView>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_incorrect_title')}</Text>
          <Text style={styles.section_body}>{performanceInfo?.incorrect || 0}</Text>
        </SectionView>
        <SectionView style={{ flex: 1 }}>
          <Text style={styles.section_title}>{t('live_session_misses_title')}</Text>
          <Text style={styles.section_body}>{performanceInfo?.misses || 0}</Text>
        </SectionView>
      </View>
    </>
  );
};

interface TutorialViewProps {
  exerciseDetails: ExerciseDetails | undefined;
}

const TutorialView: React.FC<TutorialViewProps> = ({ exerciseDetails }: TutorialViewProps) => {
  const { t, i18n } = useTranslation();
  const title = t('live_session_tutorial_screen').replace(
    '{name}',
    exerciseDetails?.exerciseNameLang[i18n.language.toUpperCase()] || '',
  ); // Do normal replace
  const body = t('live_session_tutorial_body').replace(
    '{name}',
    exerciseDetails?.exerciseNameLang[i18n.language.toUpperCase()]?.toLowerCase() || '',
  );

  return (
    <SectionView>
      <Text style={styles.section_title}>{title}</Text>
      <Text style={styles.instructions_text}>{body}</Text>
    </SectionView>
  );
};

interface SelectionItemProps {
  text: string;
}

const SelectionItem: React.FC<SelectionItemProps> = ({ text }: SelectionItemProps) => (
  <View style={styles.selection_item}>
    <Image
      style={styles.selection_item_image}
      source={require('../../../assets/live_session/balloon.png')}
      resizeMode='contain'
    />
    <SectionView style={styles.selection_item_body}>
      <Text style={styles.instructions_text}>{text}</Text>
    </SectionView>
  </View>
);

interface SelectionViewProps {
  screen: Screen;
}

const SelectionView: React.FC<SelectionViewProps> = ({ screen }: SelectionViewProps) => {
  const { t } = useTranslation();
  const params: { [key in Screen]?: { title: string; body: string; items: string[] } } = {
    [Screen.MUSIC_SELECTION]: {
      title: t('live_session_music_screen'),
      body: t('live_session_music_body'),
      items: [
        t('live_session_music_instruments'),
        t('live_session_music_jazz'),
        t('live_session_music_piano'),
        t('live_session_music_orchestra'),
      ],
    },
    [Screen.ENVIRONMENT]: {
      title: t('live_session_environment_screen'),
      body: t('live_session_environment_body'),
      items: [
        t('live_session_environment_beach'),
        t('live_session_environment_mountain'),
        t('live_session_environment_forest'),
        t('live_session_environment_urban'),
      ],
    },
  };

  return (
    <>
      <SectionView>
        <Text style={styles.section_title}>{params[screen]?.title}</Text>
        <Text style={styles.instructions_text}>{params[screen]?.body}</Text>
      </SectionView>
      <View style={styles.row}>
        <SelectionItem text={params[screen]?.items[0] || ''} />
        <SelectionItem text={params[screen]?.items[1] || ''} />
      </View>
      <View style={styles.row}>
        <SelectionItem text={params[screen]?.items[2] || ''} />
        <SelectionItem text={params[screen]?.items[3] || ''} />
      </View>
    </>
  );
};

const FinalView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <SectionView>
      <Text style={styles.section_title}>{t('live_session_final_screen')}</Text>
      <Text style={styles.instructions_text}>{t('live_session_final_body')}</Text>
    </SectionView>
  );
};

const ControllerScreen: React.FC = (props) => {
  const { t } = useTranslation();
  return (
    <View style={styles.grow_column}>
      <SectionView>
        <Text style={styles.section_title}>{t('live_session_controller_instructions_screen')}</Text>
        <Text style={styles.instructions_text}>
          {t('live_session_controller_instructions_body')}
        </Text>
      </SectionView>
      <View style={styles.calibration_image_container}>
        <Image
          style={styles.controller_image}
          source={require('../../../assets/live_session/controller_instructions.png')}
        />
      </View>
    </View>
  );
};

export default {
  SectionView,
  UserInfo,
  ExerciseInfoView,
  AdditionalInstructions,
  BalloonsImage,
  CalibrationView,
  WelcomeView,
  SessionOverviewView,
  InstructionsView,
  GoodbyeView,
  ResultsView,
  TutorialView,
  SelectionView,
  FinalView,
  ControllerScreen,
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  grow_column: {
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    marginBottom: 70,
  },

  section_title: {
    color: '#48A9C5',
    fontSize: 17,
    lineHeight: 20,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
    marginBottom: 8,
  },

  section_body: {
    color: '#FFF',
    fontSize: 25,
    lineHeight: 30,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },

  secondary_text: {
    color: '#A7A7A7',
    fontSize: 13,
    lineHeight: 16,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },

  username_view_container: {
    width: 56,
    height: 56,
    borderRadius: 28,
    borderWidth: 2,
    borderColor: '#48A9C5',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
  },

  username_view_title: {
    color: '#707070',
    fontSize: 40,
    fontFamily: 'SegoeUI-Bold',
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: 2,
  },

  user_info: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    alignItems: 'center',
  },

  headset_info_subsection: {
    marginLeft: 16,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  balloons_image: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 70,
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },

  calibration_image_container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  calibration_image: {
    height: 280,
    width: 280,
    backgroundColor: 'transparent',
  },

  controller_image: {
    height: 290,
    width: 480,
    backgroundColor: 'transparent',
    marginLeft: 180,
  },

  additional_instructions: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 85,
    paddingVertical: 16,
    paddingHorizontal: 16,
  },

  instructions_text: {
    color: '#FFFFFF',
    fontSize: 15,
    lineHeight: 18,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },

  selection_item: {
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    marginTop: 16,
  },

  selection_item_body: {
    width: '80%',
    borderRadius: 30,
  },

  selection_item_image: {
    width: 53,
    height: 116,
    marginBottom: 8,
  },

  session_overview_body: {
    marginHorizontal: 40,
    marginTop: 35,
    paddingBottom: 25,
  },
  session_overview_title: {
    fontSize: 15,
    color: '#fff',
    opacity: 0.7,
    textAlign: 'center',
    marginTop: 15,
    textTransform: 'uppercase',
  },
  session_overview_row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  session_overview_row_text: {
    color: '#fff',
    fontSize: 15,
  },
  session_overview_dotted: {
    paddingTop: 15,
    borderTopWidth: 2,
    borderColor: '#fff',
    borderStyle: 'dotted',
  },

  control_buttons: {
    position: 'absolute',
    right: 8,
    top: 20,
  },
});
