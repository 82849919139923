import React from "react";
import { Balloon as BalloonInterface, Hand } from "../api/model/LiveSession";
import { Balloon } from "./Balloon";
import { Hands } from "./Hands";

interface BalloonsProps {
  balloons: BalloonInterface[];
  hands: Hand[];
}

export const Balloons = ({ balloons, hands }: BalloonsProps) => {
    let balloonsDraftedList = balloons ? balloons.map((balloon, index) => {
      const { screenLocationX, screenLocationY, speed, asset, screenLocationZ } = balloon;
      return <Balloon initialPosition={{x: screenLocationX, y: screenLocationY, z: screenLocationZ }} asset={asset} speed={speed} key={index} />
    }) : [];

    return (
      <React.Fragment>
        {balloonsDraftedList}
        <Hands hands={hands} />
      </React.Fragment>
    )
  }