import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { HasNavigation } from '../Navigation';

interface ProfileInfoProps extends HasNavigation {
  user: UserModel;
  style?: StyleProp<ViewStyle>;
}

const ProfileInfo: FC<ProfileInfoProps> = ({ user, style, ...rest }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.container, style]} {...rest}>
      <View style={styles.stats_item_container}>
        <Text style={styles.stats_item_label}>{t('profile_main_physical')}</Text>
        <Text style={styles.stats_item_value}>{user.statsPhysical}</Text>
        <Text style={styles.stats_item_sub}>{t('profile_main_exercises')}</Text>
      </View>
      <View style={styles.stats_item_container}>
        <Text style={styles.stats_item_label}>{t('profile_main_focus')}</Text>
        <Text style={styles.stats_item_value}>{user.statsFocus}</Text>
        <Text style={styles.stats_item_sub}>{t('profile_main_exercises')}</Text>
      </View>
      <View style={styles.stats_item_container}>
        <Text style={styles.stats_item_label}>{t('profile_main_switch')}</Text>
        <Text style={styles.stats_item_value}>{user.statsSwitch}</Text>
        <Text style={styles.stats_item_sub}>{t('profile_main_exercises')}</Text>
      </View>
      <View style={styles.stats_item_container}>
        <Text style={styles.stats_item_label}>{t('profile_main_memory')}</Text>
        <Text style={styles.stats_item_value}>{user.statsMemory}</Text>
        <Text style={styles.stats_item_sub}>{t('profile_main_exercises')}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },

  stats_item_container: {
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
  },

  stats_item_label: {
    color: '#48A9C5',
    fontFamily: 'SegoeRegular',
    fontSize: 15,
  },

  stats_item_sub: {
    color: '#FFFFFFB2',
    fontFamily: 'SegoeRegular',
    fontSize: 12,
  },

  stats_item_value: {
    color: '#FFFFFF',
    flexDirection: 'row',
    fontFamily: 'SegoeRegular',
    fontSize: 20,
    marginBottom: -5,
  },
});

export default ProfileInfo;
