import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';
import StatsModel from '../model/StatsModel';
import StatsView from './StatsView';

interface ProfileStatsItemProps extends TouchableOpacityProps {
  title: string;
  stats: StatsModel;
  style?: StyleProp<ViewStyle>;
}

const ProfileStatsItem: FC<ProfileStatsItemProps> = ({ title, stats, style, ...rest }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.container, style]} {...rest}>
      <Text style={styles.title}>{title}</Text>
      <View style={[styles.stats, style]}>
        {(stats.isUp && (
          <Image
            source={require('../../assets/icon_arrow_up.png')}
            style={{ width: 15, height: 15 }}
          />
        )) || <View style={{ width: 15, height: 15 }} />}
        <StatsView
          style={{ flex: 1 }}
          label={t('profile_stats_last_session')}
          value={stats.last}
          unit={stats.unit}
          isUp={stats.isUp}
        />
        <StatsView
          style={{ flex: 1 }}
          label={t('profile_stats_session_average')}
          value={stats.average}
          unit={stats.unit}
        />
        <StatsView
          style={{ flex: 1 }}
          label={t('profile_stats_total')}
          value={stats.total}
          unit={stats.unit}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: '#0A100FB2',
    borderRadius: 15,
    paddingStart: 12,
    paddingTop: 8,
    paddingEnd: 15,
    paddingBottom: 15,
    alignItems: 'stretch',
    marginBottom: 12,
  },

  title: {
    color: '#48A9C5',
    fontFamily: 'SegoeRegular',
    fontSize: 17,
    textAlign: 'center',
    marginBottom: 10,
  },

  stats: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
});

export default ProfileStatsItem;
