import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, View } from 'react-native';
import { HasNavigation } from '../Navigation';
import SButton from './SButton';

interface Props extends HasNavigation {
  onAccepted: () => void;
}

export default ({ onAccepted }: Props) => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Text style={[styles.text]}>
        End-user terms and conditions
        <br />
        1. THE SMARTPHONE AND HEADSET SOFTWARE BY SENOPI (COLLECTIVELY THE SOFTWARE), ARE PROVIDED
        "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO
        EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
        LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN
        CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
        <br />
        2. The Software does not acquire any personal information identifying you as an individual.
        Nevertheless, you can exercise your right to access or delete your data by contacting
        dpo@senopi.com
        <br />
        3. By using the Software I accept the above terms and agree to the following:
        <br />
        - I will use the VR glasses and exercise equipment exclusively in a sitting position.
        <br />
        - I will use these VR glasses exclusively in a quiet environment where no objects or people
        are within the reach of my hands.
        <br />- I will take a break from using the VR glasses if I experience dizziness or nausea.
      </Text>
      <SButton style={styles.accept} label={t('login_tos_agree')} onPress={onAccepted} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  text: {
    fontSize: 17,
    fontFamily: 'SegoeLight',
    textAlign: 'justify',
    height: 390,
    overflow: 'scroll',
  },

  accept: {
    marginTop: 15,
    marginBottom: 15,
    width: 300,
  },
});
