import i18next from 'i18next';
import Backend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

const locizeOptions = {
  projectId: '21918835-ac66-45dc-842b-a999a9a7dd07',
  apiKey: '50eb8da6-1bb8-4565-9616-b3d5ee2803ae',
  referenceLng: 'en',
};

export const i18n = i18next.createInstance();

const DEFAULT_LANGUAGE = 'en';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v4',
    fallbackLng: DEFAULT_LANGUAGE,
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    react: {
      useSuspense: false,
    },
    ns: ['common'],
    defaultNS: 'common',
    backend: locizeOptions,
  });
