import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import { HasNavigation } from '../Navigation';
import SubSelectUserScreen from './SubSelectUserScreen';

interface SelectVrUserProps extends HasNavigation {
  headset?: { headset: UserProfile; user?: UserProfile };
  user?: UserProfile;
}

export default ({ user, headset, dialog, navigation, style, ...rest }: SelectVrUserProps) => {
  const { t } = useTranslation();

  const onSelectVrUser = async (userSelected: UserProfile) => {
    const api = await SenopiApi(user);
    if (userSelected.token) {
      dialog.showDialog(<ActivityIndicator color={'#FFFFFF'} />, { backgroundColor: '' });
      await api.assignUser(headset?.headset.username || '', userSelected?.username || '');
      dialog.hideDialog();
      navigation('..');
    }
  };

  return (
    <SubSelectUserScreen
      user={user}
      style={style}
      source='user'
      dialog={dialog}
      onSelectUser={onSelectVrUser}
      navigation={navigation}
      {...rest}
    />
  );
};
