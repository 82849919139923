import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ImageStyle, StyleProp, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { HasNavigation } from '../Navigation';
import IconButton from './IconButton';
import IconText from './IconText';
import SenopiApi from '../api/SenopiApi';

interface Props extends HasNavigation {}

const LangClosed = ({ ...rest }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const { t, i18n } = useTranslation();

  const setLangLocal = async (x: string) => {
    i18n.changeLanguage(x.toLowerCase());
    setOpen(false);

    const api = await SenopiApi();
    const user = api.userInfo;
    if (user?.type) {
      api.userInfo.language = x.toLowerCase();
      api.updateAccount(api.userInfo);
    }
  };

  const languages = ['EN', 'PL', 'DE', 'FI', 'SE'].filter((x) => x != i18n.language.toUpperCase());

  const arrowStyle: StyleProp<ImageStyle> = [{ width: 9, height: 6, marginStart: 9 }];
  if (isOpen) {
    arrowStyle.push({ transform: [{ rotate: '180deg' }] });
  }

  const insets = useSafeAreaInsets();

  return (
    <View style={[styles.container, { marginTop: insets.top + 14 }]} {...rest}>
      <TouchableOpacity onPress={() => setOpen(!isOpen)}>
        <View style={[styles.current]}>
          <IconText
            labelStyle={styles.langLabel}
            icon={require('../../assets/flag_' + i18n.language.toLowerCase() + '_round.png')}
            iconWidth={13}
            iconHeight={13}
            label={i18n.language.toUpperCase() || 'EN'}
          />
          <Image source={require('../../assets/icon_arrow_down.png')} style={arrowStyle} />
        </View>
      </TouchableOpacity>
      {isOpen &&
        languages.map((x) => (
          <IconButton
            key={x}
            labelStyle={[styles.langLabel]}
            style={[styles.langListLabel]}
            icon={require('../../assets/flag_' + x.toLowerCase() + '_round.png')}
            iconWidth={13}
            iconHeight={13}
            onPress={() => setLangLocal(x)}
            label={x}
          />
        ))}
    </View>
  );
};

export default ({ ...rest }: Props) => {
  return <LangClosed {...rest} />;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: 14,
    zIndex: 1000,
    width: 55,
    position: 'absolute',
    left: 0,
  },

  current: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  langLabel: {
    color: '#FFFFFF',
    fontFamily: 'SegoeRegular',
    fontSize: 13,
  },

  langListLabel: {
    justifyContent: 'flex-start',
    width: 55,
    marginTop: 3,
    marginBottom: 3,
  },
});
