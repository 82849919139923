import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlatList,
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { ExerciseInfo, LiveSessionData } from '../api/model/LiveSession';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import ListItem from '../elements/ListItem';
import { HasNavigation } from '../Navigation';
import { userFullName } from '../Utils';

interface Props extends HasNavigation {
  user?: UserProfile;
}

interface ExerciseListAction {
  icon: ImageSourcePropType;
  width: number;
  height: number;
  action: (liveSession: LiveSessionData) => void;
}

const isRequiredInfoNotExists = (exercise: ExerciseInfo) => {
  return (
    exercise.screen === 'GAME' &&
    (!exercise.background ||
      !exercise.exerciseInfo ||
      !exercise.exerciseInfo?.exerciseName ||
      !exercise.exerciseInfo?.instructions ||
      !exercise.performanceInfo ||
      exercise.performanceInfo?.correct === undefined ||
      exercise.performanceInfo?.incorrect === undefined ||
      exercise.performanceInfo?.misses === undefined ||
      exercise.performanceInfo?.timeLeft === undefined)
  );
};

const ItemInfo = ({
  liveSession,
  action,
}: {
  liveSession: LiveSessionData;
  action: ExerciseListAction;
}) => {
  const userLabel =
    userFullName(liveSession.userAccessInfo) + '(' + liveSession.userAccessInfo.username + ')';
  const headsetLabel = liveSession.exercise.headsetId;
  return (
    <ListItem key={liveSession.exercise.headsetId}>
      <TouchableOpacity style={styles.item} onPress={() => action.action(liveSession)}>
        <View style={styles.item_info}>
          <Text style={styles.item_info_title}>{headsetLabel}</Text>
          <Text style={styles.item_info_label}>{userLabel}</Text>
        </View>
        <View style={styles.action}>
          <Image style={{ width: action.width, height: action.height }} source={action.icon} />
        </View>
      </TouchableOpacity>
    </ListItem>
  );
};

export default ({ user, navigation, style }: Props) => {
  const { t } = useTranslation();
  const [liveSessions, setLiveSessions] = useState<LiveSessionData[]>([]);

  const liveSessionsList = async () => {
    const api = await SenopiApi(user);

    const result = await api.liveSessions();
    setLiveSessions(result);
  };

  useEffect(() => {
    liveSessionsList();
  }, []);

  const action: ExerciseListAction = {
    icon: require('../../assets/icon_play.png'),
    width: 36,
    height: 36,
    action: (liveSession: LiveSessionData) => {
      const { exercise, userAccessInfo } = liveSession;
      if (isRequiredInfoNotExists(exercise)) return;
      navigation('subadminExerciseInfo', true, { exercise, user: userAccessInfo });
    },
  };

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} />
      <View style={[styles.content]}>
        <Text style={[styles.title]}>{t('live_session_title')}</Text>
        <FlatList
          style={styles.list}
          ListEmptyComponent={
            <Text style={styles.empty_subtitle}>{t('live_session_empty_subtitle')}</Text>
          }
          data={liveSessions}
          renderItem={({ item }: { item: LiveSessionData }) => (
            <ItemInfo liveSession={item} action={action} />
          )}
          scrollEnabled={true}
          keyExtractor={(_, index) => (Math.random() + index).toString()}
          ListFooterComponent={liveSessions.length ? <View style={styles.footer} /> : undefined}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  content: {
    paddingStart: 16,
    paddingEnd: 16,
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  empty_subtitle: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'SegoeRegular',
    opacity: 0.6,
    textAlign: 'center',
  },

  list: {
    marginTop: 30,
  },

  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  item_info: {
    flexDirection: 'column',
    alignContent: 'flex-start',
    flexGrow: 1,
  },

  item_info_title: {
    color: '#48A9C5',
    opacity: 0.8,
    fontSize: 16,
    fontFamily: 'SegoeRegular',
  },

  item_info_label: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 20,
    fontFamily: 'SegoeRegular',
  },

  item_button: {
    width: 24,
    height: 24,
  },

  action: {
    width: 26,
    height: 26,
    borderRadius: 26,
    backgroundColor: '#48A9C5',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
  },

  footer: {
    paddingTop: 15,
    borderTopColor: '#FFFFFF99',
    borderTopWidth: 1,
  },
});
