import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center',
  },
  label: {
    fontSize: 18,
    color: '#fff',
    textAlign: 'left',
  },
  user: {
    marginTop: 5,
    height: 50,
    borderRadius: 9,
    borderColor: 'rgba(255, 255, 255, 0.7)',
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  username: {
    fontSize: 20,
    color: 'rgba(255, 255, 255, 0.8)',
  },
  icon_wrapper: {
    width: 25,
    height: 25,
    borderRadius: 25,
    backgroundColor: '#48A9C5',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 14,
    height: 14,
  },
  title_main: {
    fontSize: 20,
    color: '#fff',
    marginTop: 15,
    marginBottom: 38,
  },
  dropdown: {
    width: '100%',
    marginTop: 10,
  },
  text_button: {
    marginTop: 40,
  },
});
