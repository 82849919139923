import { Suspense, useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { useTranslation } from 'react-i18next';
import { ImageBackground, ImageSourcePropType, StyleSheet, View } from 'react-native';
import { HasNavigation } from '../../Navigation';
import { SocketEvent } from '../../api/Socket';
import { ExerciseInfo, Screen } from '../../api/model/LiveSession';
import UserProfile from '../../api/model/UserProfile';
import SButton from '../../elements/SButton';
import useSocketIO from '../../hooks/useSocketIO';
import Content from './Content';
import { Balloons } from '../../components/Balloons';
import { Loading } from '../../components/Loading';

interface Props extends HasNavigation {
  exercise: ExerciseInfo;
  user: UserProfile;
}

export default ({ exercise, user, navigation, style }: Props) => {
  const { t } = useTranslation();
  const [currentExercise, setExercise] = useState<ExerciseInfo>(exercise);
  const { message, sendCommand } = useSocketIO<ExerciseInfo>(user, SocketEvent.State);

  useEffect(() => {
    setExercise((prevExercise) => ({ ...prevExercise, ...message }));
  }, [message, setExercise]);

  const getImagePath = (): ImageSourcePropType => {
    if (currentExercise.screen !== Screen.GAME)
      return require('../../../assets/live_session/live_session_background.png');

    try {
      const path = require(`../../../assets/live_session/${currentExercise.background}.png`);
      return path;
    } catch {
      return require('../../../assets/live_session/ATM00510-7680.png');
    }
  };

  const getContent = (): JSX.Element | null => {
    switch (currentExercise.screen) {
      case Screen.CALIBRATION_01:
        return <Content.CalibrationView step={1} />;
      case Screen.CALIBRATION_02:
        return <Content.CalibrationView step={2} />;
      case Screen.POINTER_SELECTION:
        return <Content.ControllerScreen />;
      case Screen.ENVIRONMENT:
        return <Content.SelectionView screen={currentExercise.screen} />;
      case Screen.GAME:
        return <Content.ExerciseInfoView exercise={currentExercise} />;
      case Screen.GOODBYE:
        return <Content.GoodbyeView />;
      case Screen.INSTRUCTIONS:
        return <Content.InstructionsView exerciseInfo={currentExercise.exerciseInfo} />;
      case Screen.MUSIC_SELECTION:
        return <Content.SelectionView screen={currentExercise.screen} />;
      case Screen.OVERVIEW:
        return <Content.SessionOverviewView />;
      case Screen.RESULTS:
        return <Content.ResultsView performanceInfo={currentExercise.performanceInfo} />;
      case Screen.TUTORIAL:
        return <Content.TutorialView exerciseDetails={currentExercise.exerciseInfo} />;
      case Screen.WELCOME:
        return <Content.WelcomeView />;
      case Screen.FINAL_OVERVIEW:
        return <Content.FinalView />;
      default:
        return null;
    }
  };
  
  return (
    <ImageBackground style={[styles.container, style]} source={getImagePath()} resizeMode='cover'>
      <Content.BalloonsImage screen={currentExercise.screen} />
      <View style={styles.content}>
        <Content.UserInfo user={user} currentExercise={currentExercise} sendCommand={sendCommand} />
        {getContent()}
      </View>
      <Content.AdditionalInstructions screen={currentExercise.screen} />
      <Canvas camera={{ position: [0, 0, 15] }} style={{ display: "flex", flex: 1 }}>
        <ambientLight />
        <Suspense fallback={<Loading />}>
          <Balloons hands={currentExercise?.performanceInfo?.hands} balloons={currentExercise?.performanceInfo?.balloons} />
        </Suspense>
      </Canvas>
      <SButton
        style={styles.back_button}
        label={t('go_back')}
        onPress={() => navigation('supervisorHome')}
      />
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  content: {
    paddingStart: 8,
    paddingEnd: 8,
    flex: 1,
    zIndex: 3
  },

  back_button: {
    position: 'absolute',
    bottom: 16,
    left: 60,
    right: 60,
  },
});
