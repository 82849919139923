import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
  },

  button: {
    width: '100%',
    maxWidth: 250,
    marginTop: 80,
    zIndex: -1,
  },
});
