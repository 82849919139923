export interface HeadsetSettings {
  language: string;
  environment: EnvironmentEnum;
  music: MusicEnum;
  modules: HeadsetSettingsModule[];
}

export interface HeadsetSettingsModule {
  name: ExerciseEnum;
  duration: number;
  level: number;
}

export enum EnvironmentEnum {
  Beach = 'Beach',
  Mountain = 'Mountains',
  Forest = 'Forest',
  Sightseeing = 'Sightseeing',
}

export enum MusicEnum {
  StringInstruments = 'String',
  Jazz = 'Jazz',
  Orchestra = 'Orchestra',
  Piano = 'Piano',
}

export enum ExerciseEnum {
  Focus = 'Focus',
  Switch = 'Switch',
  Memory = 'Memory',
  Warmup = 'Warmup',
  Empty = '',
}
