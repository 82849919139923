import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { HasNavigation } from '../Navigation';
import UserProfile from '../api/model/UserProfile';
import CheckBoxLabel from '../elements/CheckBoxLabel';
import { Dialog } from '../elements/Dialog';
import DropDown from '../elements/DropDown';
import HeaderPane from '../elements/HeaderPane';
import SButton from '../elements/SButton';
import SectionView from '../elements/SectionView';
import TitledInput from '../elements/TitledInput';

interface Props extends HasNavigation {
  user?: UserProfile;
  isEdit: boolean;
  onSave?: (form: UserProfile) => void;
  onBack?: () => void;
}

const HeaderRight = ({
  isEdit,
  onInfoPress,
  onDeletePress,
}: {
  isEdit: boolean;
  onInfoPress: () => void;
  onDeletePress?: () => void;
}) => (
  <View style={{ flexDirection: 'row' }}>
    <TouchableOpacity style={{ padding: 14 }} onPress={onInfoPress}>
      <Image
        source={require('../../assets/icon_user_info.png')}
        style={{ width: 20, height: 20 }}
      />
    </TouchableOpacity>
    {isEdit ? (
      <TouchableOpacity style={{ padding: 14 }} onPress={onDeletePress}>
        <Image source={require('../../assets/icon_delete.png')} style={{ width: 18, height: 21 }} />
      </TouchableOpacity>
    ) : null}
  </View>
);

const RenderCheckboxes = ({
  values,
  title,
}: {
  title: string;
  values: Array<{
    label: string;
    isChecked: boolean;
    onCheckedChange: (checked: boolean) => void;
  }>;
}) => {
  return (
    <>
      <Text style={[styles.title, { textAlign: 'left' }]}>{title}</Text>
      <View style={{ marginTop: 20 }}>
        {values.map((item, index) => (
          <CheckBoxLabel
            styleIconContainer={{ borderRadius: 5 }}
            onCheckedChange={item.onCheckedChange}
            key={index.toString()}
            style={styles.checkBox}
            styleLabel={{ marginLeft: 10, textAlign: 'left' }}
            isChecked={item.isChecked}
            label={item.label}
          />
        ))}
      </View>
    </>
  );
};

export default ({ user, isEdit, style, onSave, onBack, navigation }: Props) => {
  const { t } = useTranslation();
  //TODO: user cloneDeep should be here to avoid updating parent object
  const onDeletePress = async () => {
    //Do API call
    setDeleteVisible(false);
    setSuccessVisible(true);
    setTimeout(() => {
      setSuccessVisible(false);
      navigation('..');
    }, 2000);
  };

  const [infoVisible, setInfoVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [successVisible, setSuccessVisible] = useState(false);

  const [form, setForm] = useState<UserProfile>(Object.assign(new UserProfile(), user));
  const setFormValue = (update: (form: UserProfile) => void) => {
    const formLocal = { ...form };
    update(formLocal);
    setForm({ ...formLocal });
  };

  return (
    <>
      <View style={[styles.container, style]}>
        <HeaderPane
          onBackPressed={() => (onBack ? onBack() : navigation('..'))}
          rightComponent={
            <HeaderRight
              isEdit={isEdit}
              onInfoPress={() => setInfoVisible((prev) => !prev)}
              onDeletePress={() => setDeleteVisible(true)}
            />
          }
        />
        <ScrollView style={styles.scroll} contentContainerStyle={styles.scroll_content}>
          <Text style={styles.title}>
            {isEdit ? t('superviser_user_edit_title') : t('superviser_user_create_title')}
          </Text>
          <TitledInput
            style={{ marginTop: 20 }}
            label={t('superviser_user_create_user_username')}
            subLabel={
              <Text style={styles.usercode_title}>
                {t('superviser_user_create_user_usercode')}
                <Text style={{ color: '#868686' }}>{user?.username}</Text>
              </Text>
            }
            value={form.privateData.username || ''}
            onChangeText={(value) => setFormValue((form) => (form.privateData.username = value))}
          />
          <TitledInput
            style={{ marginTop: 20 }}
            label={t('superviser_user_create_user_email')}
            keyboardType='email-address'
            textContentType='emailAddress'
            value={form.privateData?.email || ''}
            onChangeText={(value) => setFormValue((form) => (form.privateData.email = value))}
          />
          <TitledInput
            style={{ marginTop: 20, marginRight: 20, flex: 1 }}
            label={t('superviser_user_create_user_phone')}
            keyboardType='number-pad'
            value={form.privateData?.phone || ''}
            onChangeText={(value) => setFormValue((form) => (form.privateData.phone = value))}
          />
          <View style={[styles.row, { zIndex: 4000 }]}>
            <TitledInput
              style={{ marginTop: 20, marginRight: 20, flex: 1 }}
              label={t('superviser_user_create_user_age')}
              keyboardType='number-pad'
              value={form.commonData?.age || ''}
              onChangeText={(value) => setFormValue((form) => (form.commonData.age = value))}
            />
            <DropDown
              style={{ flex: 3, marginTop: 20 }}
              label={t('superviser_user_create_user_gender')}
              placeholderText={t('superviser_user_create_select_gender')}
              zIndex={4000}
              zIndexInverse={1000}
              value={form.commonData.gender}
              items={[
                { label: t('superviser_user_create_male'), value: 'male' },
                { label: t('superviser_user_create_female'), value: 'female' },
                { label: t('superviser_user_create_unspecified'), value: 'unspecified' },
              ]}
              onValueChange={(value) =>
                setFormValue((form) => (form.commonData.gender = value.value))
              }
            />
          </View>
          <DropDown
            style={{ marginTop: 20, marginBottom: 30 }}
            label={t('superviser_user_create_ethnicity')}
            placeholderText={'Dropdown'}
            value={form.commonData?.ethnicity}
            items={[
              { label: t('superviser_user_create_ethnicity_african'), value: 'african' },
              { label: t('superviser_user_create_ethnicity_arabic'), value: 'arabic' },
              { label: t('superviser_user_create_ethnicity_asian'), value: 'asian' },
              { label: t('superviser_user_create_ethnicity_caucasian'), value: 'caucasian' },
              { label: t('superviser_user_create_ethnicity_hispanic_latino'), value: 'hispanic' },
              { label: t('superviser_user_create_ethnicity_indian'), value: 'indian' },
            ]}
            onValueChange={(value) =>
              setFormValue((form) => (form.commonData.ethnicity = value.value))
            }
            zIndex={3000}
            zIndexInverse={1000}
          />
          <DropDown
            style={{ marginTop: 20, marginBottom: 30 }}
            label={t('superviser_user_create_marital_status')}
            placeholderText={'Dropdown'}
            value={form.commonData?.maritalStatus}
            items={[
              { label: t('superviser_user_create_marital_single'), value: 'single' },
              { label: t('superviser_user_create_marital_married'), value: 'married' },
              { label: t('superviser_user_create_marital_divorced'), value: 'divorced' },
              { label: t('superviser_user_create_marital_widowed'), value: 'widowed' },
            ]}
            onValueChange={(value) =>
              setFormValue((form) => (form.commonData.maritalStatus = value.value))
            }
            zIndex={2000}
            zIndexInverse={2000}
          />
          <DropDown
            style={{ marginTop: 20, marginBottom: 30 }}
            label={t('superviser_user_create_education')}
            placeholderText={'Dropdown'}
            value={form.commonData?.educationLevel}
            items={[
              { label: t('superviser_user_create_education_none'), value: 'none' },
              {
                label: t('superviser_user_create_education_primary_studies'),
                value: 'primary_studies',
              },
              {
                label: t('superviser_user_create_education_secondary_studies'),
                value: 'secondary_studies',
              },
              { label: t('superviser_user_create_education_high_school'), value: 'high_school' },
              {
                label: t('superviser_user_create_education_bachelor_degree'),
                value: 'bachelor_degree',
              },
              {
                label: t('superviser_user_create_education_master_degree'),
                value: 'master_degree',
              },
              { label: t('superviser_user_create_education_doctorate'), value: 'doctorate' },
            ]}
            onValueChange={(value) =>
              setFormValue((form) => (form.commonData.educationLevel = value.value))
            }
            zIndex={1000}
            zIndexInverse={3000}
          />
          <Text style={[styles.title, { textAlign: 'left' }]}>
            {t('superviser_user_create_employed')}
          </Text>
          <View style={{ flexDirection: 'row', marginTop: 20 }}>
            <CheckBoxLabel
              styleIconContainerChecked={styles.styleIconContainerChecked}
              withoutIcon
              isChecked={form.commonData?.employed}
              label={t('yes')}
              styleLabel={{ marginLeft: 10 }}
              onCheckedChange={(value) =>
                setFormValue((form) => (form.commonData.employed = value))
              }
            />
            <CheckBoxLabel
              styleIconContainerChecked={styles.styleIconContainerChecked}
              withoutIcon
              isChecked={!form.commonData?.employed}
              label={t('no')}
              style={{ marginLeft: 20 }}
              styleLabel={{ marginLeft: 10 }}
              onCheckedChange={(value) =>
                setFormValue((form) => (form.commonData.employed = value))
              }
            />
          </View>

          <RenderCheckboxes
            title={t('superviser_user_create_medical_condition')}
            values={[
              {
                label: t('superviser_user_create_medical_hipertension'),
                isChecked: form.commonData?.hypertension || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.hypertension = value)),
              },
              {
                label: t('superviser_user_create_medical_diabetes'),
                isChecked: form.commonData?.diabetes || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.diabetes = value)),
              },
              {
                label: t('superviser_user_create_medical_cancer'),
                isChecked: form.commonData?.cancer || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.cancer = value)),
              },
              {
                label: t('superviser_user_create_medical_heart_failure'),
                isChecked: form.commonData?.heartFailure || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.heartFailure = value)),
              },
              {
                label: t('superviser_user_create_medical_epilepsy'),
                isChecked: form.commonData?.epilepsy || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.epilepsy = value)),
              },
            ]}
          />

          <RenderCheckboxes
            title={t('superviser_user_create_neurological_condition')}
            values={[
              {
                label: t('superviser_user_create_neurological_alzheimer'),
                isChecked: form.commonData?.alzgeimer || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.alzgeimer = value)),
              },
              {
                label: t('superviser_user_create_neurological_parkinson'),
                isChecked: form.commonData?.parkinson || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.parkinson = value)),
              },
              {
                label: t('superviser_user_create_neurological_mci'),
                isChecked: form.commonData?.mildCongnitiveImpairment || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.mildCongnitiveImpairment = value)),
              },
              {
                label: t('superviser_user_create_neurological_frontotemporal_dementia'),
                isChecked: form.commonData?.frontotemporalDementia || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.frontotemporalDementia = value)),
              },
              {
                label: t('superviser_user_create_neurological_vascular_dementia'),
                isChecked: form.commonData?.vascularDementia || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.vascularDementia = value)),
              },
              {
                label: t('superviser_user_create_neurological_mixed_dementia'),
                isChecked: form.commonData?.mixedDementia || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.mixedDementia = value)),
              },
              {
                label: t('superviser_user_create_neurological_dementia_with_lewy_bodies'),
                isChecked: form.commonData?.dementiaWithLewyBodies || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.dementiaWithLewyBodies = value)),
              },
            ]}
          />

          <RenderCheckboxes
            title={t('superviser_user_create_psychiatric_condition')}
            values={[
              {
                label: t('superviser_user_create_psychiatric_depression'),
                isChecked: form.commonData?.depression || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.depression = value)),
              },
              {
                label: t('superviser_user_create_psychiatric_anxiety'),
                isChecked: form.commonData?.anxiety || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.anxiety = value)),
              },
              {
                label: t('superviser_user_create_psychiatric_schizophrenia'),
                isChecked: form.commonData?.schizophrenia || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.schizophrenia = value)),
              },
              {
                label: t('superviser_user_create_psychiatric_frailty'),
                isChecked: form.commonData?.frailty || false,
                onCheckedChange: (value) =>
                  setFormValue((form) => (form.commonData.frailty = value)),
              },
            ]}
          />

          <TitledInput
            style={{ marginVertical: 20 }}
            inputStyle={{ height: 100, textAlignVertical: 'top' }}
            label={t('superviser_user_create_user_notes')}
            value={form.privateData?.notes || ''}
            onChangeText={(value) => setFormValue((form) => (form.privateData.notes = value))}
            multiline
          />
          <SButton
            label={!isEdit ? t('superviser_user_create_update') : t('superviser_user_create_save')}
            onPress={() => onSave?.(form)}
          />
        </ScrollView>
      </View>
      <DialogInfo infoVisible={infoVisible} setInfoVisible={setInfoVisible} />
      {user && (
        <DialogDelete
          deleteVisible={deleteVisible}
          setDeleteVisible={setDeleteVisible}
          user={user}
          onDeletePress={onDeletePress}
        />
      )}
      <DialogSuccess successVisible={successVisible} setSuccessVisible={setSuccessVisible} />
    </>
  );
};

interface DialogDeleteProps {
  deleteVisible: boolean;
  setDeleteVisible: (value: boolean) => void;
  user: UserProfile;
  onDeletePress: () => void;
}

const DialogDelete: FC<DialogDeleteProps> = ({
  deleteVisible,
  setDeleteVisible,
  user,
  onDeletePress,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog isVisible={deleteVisible} onDismiss={() => setDeleteVisible(false)}>
      <Text style={styles.info_title}>{t('superviser_user_delete_title')}</Text>
      {user.privateData?.username && (
        <Text style={[styles.usercode_title, { marginTop: 20, textAlign: 'center' }]}>
          {t('superviser_user_delete_username')}
          <Text style={{ color: '#868686' }}>{user.privateData?.username}</Text>
        </Text>
      )}
      <Text
        style={[styles.usercode_title, { marginTop: 10, marginBottom: 30, textAlign: 'center' }]}
      >
        {t('superviser_user_create_user_usercode')}
        <Text style={{ color: '#868686' }}>{user.username}</Text>
      </Text>
      <SButton
        style={{ marginBottom: 10, width: '100%' }}
        label={t('superviser_user_delete_confirm')}
        onPress={onDeletePress}
      />
      <SButton
        style={{ width: '100%' }}
        label={t('superviser_user_delete_cancel')}
        onPress={() => setDeleteVisible(false)}
      />
    </Dialog>
  );
};

interface DialogInfoProps {
  infoVisible: boolean;
  setInfoVisible: (value: boolean) => void;
}

const DialogInfo: FC<DialogInfoProps> = (props) => {
  const { infoVisible, setInfoVisible } = props;
  const { t } = useTranslation();
  return (
    <Dialog isVisible={infoVisible} onDismiss={() => setInfoVisible(false)}>
      <SectionView style={{ alignItems: 'center', paddingVertical: 20 }}>
        <Text style={styles.info_title}>{t('superviser_user_create_info_title')}</Text>
        <Text style={styles.info_body}>{t('superviser_user_create_info_body')}</Text>
      </SectionView>
    </Dialog>
  );
};

interface DialogSuccessProps {
  successVisible: boolean;
  setSuccessVisible: (value: boolean) => void;
}

const DialogSuccess: FC<DialogSuccessProps> = (props) => {
  const { successVisible, setSuccessVisible } = props;
  const { t } = useTranslation();
  return (
    <Dialog isVisible={successVisible} onDismiss={() => setSuccessVisible(false)}>
      <Image
        source={require('../../assets/icon_success.png')}
        style={{ width: 100, height: 100 }}
      />
      <Text style={styles.title}>{t('superviser_user_delete_success')}</Text>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
  },
  scroll: {
    flexGrow: 1,
  },
  scroll_content: {
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: 'row',
  },
  title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 20,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
    marginTop: 20,
  },
  usercode_title: {
    color: '#FFFFFF',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'right',
    fontFamily: 'SegoeRegular',
  },
  info_title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
    marginBottom: 10,
  },
  info_body: {
    color: '#868686',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
  },
  delete_modal_container: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000090',
    paddingHorizontal: 20,
  },
  delete_modal_body: {
    alignSelf: 'center',
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
  styleIconContainerChecked: {
    borderWidth: 2,
    borderColor: '#fff',
  },
  checkBox: {
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
});
