import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { HasNavigation } from '../Navigation';
import SenopiApi from '../api/SenopiApi';
import UserProfile from '../api/model/UserProfile';
import HeaderPane from '../elements/HeaderPane';
import IconButton from '../elements/IconButton';
import LanguagePicker from '../elements/LanguagePicker';
import LinkText from '../elements/LinkText';
import SButton from '../elements/SButton';
import { setIsOldVersionAction, setSessionSettingsAction } from '../redux/reducers/session.reducer';
import { compareVersions } from '../Utils';

interface Props extends HasNavigation {
  title?: string;
  user?: UserProfile;
}

export default ({ navigation, user, style, ...rest }: Props) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useState<UserProfile>(
    Object.assign(new UserProfile(), user),
  );

  const onLogout = async () => {
    const api = await SenopiApi();
    api.logout();
    navigation('login', false);
  };

  const loadProfile = async () => {
    const api = await SenopiApi(user);
    if (!user) {
      setCurrentUser(Object.assign(new UserProfile(), api.userInfo));
    }
    try {
      const user = api.userInfo;
      setFirstName(user.firstName || 'Anonymous');
      setLastName(user.lastName || '');
      setType(user.type || 'supervisor');
    } catch (e) {
      console.error(e);
    }
  };

  const eventsReport = async () => {
    const api = await SenopiApi(user);
    const downloadUrl = api.eventsReport();
    await Linking.openURL(downloadUrl);
  };

  useEffect(() => {
    loadProfile();
  }, []);

  const navigateToSession = async () => {
    const api = await SenopiApi(user);
    if (await checkVersionIsOldVersion()) {
      await runOldLogic();
      return;
    }
    await setupSettings(api);
    const result = await api.liveSessions();
    if (result.length) {
      navigation('subadminExerciseInfo', true, {
        exercise: result[0].exercise,
        user: result[0].userAccessInfo,
      });
    } else {
      navigation('setupSessionScreen', true, { user: currentUser, source: 'supervisor' });
    }
  };

  const checkVersionIsOldVersion = async () => {
    const api = await SenopiApi(user);
    const result = await api.headsets();
    if (!result.length) return false;
    const version = result[0].headsetVersion;
    if (version && compareVersions(version, '1.1') >= 0) {
      return false;
    } else {
      return true;
    }
  };

  const runOldLogic = async () => {
    dispatch(setIsOldVersionAction(true));
    navigation('superviserStartSessionOld', true, { user: currentUser, source: 'supervisor' });
  };

  const setupSettings = async (api: any) => {
    try {
      const settings = await api.getHeadsetSettings();
      dispatch(setSessionSettingsAction(settings));
    } catch (e) {}
  };

  return (
    <View style={[styles.container, style]}>
      {user && <HeaderPane onBackPressed={() => navigation('..')} />}
      <LanguagePicker navigation={navigation} dialog={rest.dialog} />
      <Image
        source={require('../../assets/logo_main.png')}
        style={{ width: 198, height: 50, marginTop: 48 }}
      />
      <Image source={require('../../assets/icon.png')} style={styles.photo} resizeMode='cover' />
      <Text style={styles.title}>
        {firstName} {lastName}
      </Text>
      <View style={styles.form}>
        <SButton
          label={t('home_superviser_start_session')}
          style={styles.form_button}
          onPress={navigateToSession}
        />
        <SButton
          label={t('home_superviser_users')}
          style={styles.form_button}
          onPress={() =>
            navigation('superviserUsers', true, { user: currentUser, source: 'supervisor' })
          }
        />
      </View>
      <LinkText label={t('home_superviser_report')} onPress={eventsReport} />
      <View style={{ flexGrow: 1 }} />
      <IconButton
        label={t('home_sign_out')}
        icon={require('../../assets/icon_signout.png')}
        iconWidth={18}
        iconHeight={16}
        onPress={onLogout}
      />
      <View style={{ flexGrow: 1 }} />
      <Image
        source={require('../../assets/logo_developed_by.png')}
        resizeMode='contain'
        style={{ width: 97, height: 46, marginBottom: 10, marginTop: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  photo: {
    marginTop: 48,
    width: 120,
    height: 120,
    borderWidth: 4,
    borderColor: '#48A9C5',
    borderRadius: 60,
  },

  title: {
    color: '#48A9C5',
    fontSize: 29,
    fontFamily: 'SegoeRegular',
    marginTop: 19,
  },

  token: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'SegoeRegular',
    marginTop: 5,
  },

  form: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 49,
    marginBottom: 46,
  },

  form_button: {
    marginBottom: 17,
  },
});
