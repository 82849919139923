import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import SenopiApi from '../../api/SenopiApi';
import { Dialog } from '../../elements/Dialog';
import HeaderPane from '../../elements/HeaderPane';
import LoginInput from '../../elements/LoginInput';
import SButton from '../../elements/SButton';
import SText from '../../elements/SText';
import { styles } from './styles';
import { ResetPasswordScreenProps } from './types';

const ResetPasswordScreen: FC<ResetPasswordScreenProps> = (props) => {
  const { style, navigation, dialog } = props;

  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isDialogShown, setIsDialogShown] = useState(false);

  const emailHandler = (text: string) => setEmail(text);

  const goBack = () => {
    navigation('..');
  };

  const onReset = async () => {
    if(!email) return
    dialog.showLoading();
    try {
      const api = await SenopiApi();
      await api.userRestore(email)
      setIsDialogShown(true);
    } catch (error) {console.error(error)}
    dialog.hideLoading();
  };

  return (
    <>
      <View style={[styles.container, style]}>
        <HeaderPane onBackPressed={goBack} style={{ width: '100%' }} />
        <SText style={styles.subtitle}>{t('reset_please_enter')}</SText>
        <View style={styles.form}>
          <LoginInput
            onChangeText={emailHandler}
            label={t('login_email')}
            textContentType='emailAddress'
            iconWidth={13}
            iconHeight={15}
            value={email}
          />
          <SButton
            disabled={!email}
            style={styles.button}
            label={t('reset_password')}
            onPress={onReset}
          />
        </View>
      </View>

      <Dialog isVisible={isDialogShown} onDismiss={goBack}>
        <SText style={styles.dialogText}>{t('reset_password_popup')}</SText>
        <SButton label={t('continue')} onPress={goBack} />
      </Dialog>
    </>
  );
};

export default ResetPasswordScreen;
