import React from "react";
import { useLoader } from "@react-three/fiber";
import { TextureLoader } from "expo-three";
import { Hand } from "../api/model/LiveSession";
import { blueLeft, blueRight, greenLeft, greenRight, orangeLeft, orangeRight, pinkLeft, pinkRight, whiteLeft, whiteRight, yellowLeft, yellowRight } from "../assets/hands";


const assets: Record<string,string> = {
  blue_left: blueLeft,
  blue_right: blueRight,
  green_left: greenLeft,
  green_right: greenRight,
  orange_left: orangeLeft,
  orange_right: orangeRight,
  pink_left: pinkLeft,
  pink_right: pinkRight,
  white_left: whiteLeft,
  white_right: whiteRight,
  yellow_left: yellowLeft,
  yellow_right: yellowRight,
}

export interface HandsProps {
  hands: Hand[];
}

const getAssetImage = (asset: string) => {
  const isLeft = asset.includes("left");
  const image = assets[asset] || (isLeft ? whiteLeft : whiteRight);
  return image
}

export const Hands = ({ hands }: HandsProps) => {
      const leftHandRef = React.useRef();
      const rightHandRef = React.useRef();
      const initialPositionLeft = { screenLocationX: -6, screenLocationY: -6 };
      const initialPositionRight = { screenLocationX: 6, screenLocationY: -6 };
      const left = hands ? hands.find(hand => hand.asset.includes("left")) : undefined;
      const right = hands ? hands.find(hand => hand.asset.includes("right")) : undefined;
      // @ts-ignore
      const LeftHand = useLoader(TextureLoader, getAssetImage(left?.asset || "white_left"));
      // @ts-ignore
      const RightHand = useLoader(TextureLoader, getAssetImage(right?.asset || "white_right"));

     const leftHand = left ? left : initialPositionLeft;
     const rightHand = right ? right : initialPositionRight;
    const scale = 2.2
    return (
      <React.Fragment>
        <mesh position={[leftHand?.screenLocationX,leftHand?.screenLocationY,10]} scale={scale} ref={leftHandRef}>
          <planeGeometry attach="geometry" />
          <meshBasicMaterial attach={"material"} map={LeftHand} transparent  />
        </mesh> 
        <mesh  position={[rightHand?.screenLocationX,rightHand?.screenLocationY,10]} scale={scale} ref={rightHandRef}>
          <planeGeometry attach="geometry" />
          <meshBasicMaterial attach={"material"} map={RightHand} transparent  />
        </mesh> 
      </React.Fragment>
    )
  }
  