import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, TouchableOpacity, View } from 'react-native';
import { getUsername } from '../../../../Utils';
import { ExerciseEnum, HeadsetSettingsModule } from '../../../../api/model/HeadsetSettings';
import UserProfile from '../../../../api/model/UserProfile';
import SButton from '../../../../elements/SButton';
import SText from '../../../../elements/SText';
import { Exercise } from '../Exercise';
import { styles } from './styles';

interface ExercisesProps {
  modules: HeadsetSettingsModule[];
  onChange: (value: HeadsetSettingsModule[]) => void;
  navigation: (path: string | any, allowBack?: boolean, params?: any) => void;
  currentUser?: UserProfile | null;
}

const MAX_EXERCISES = 4

export const Exercises: FC<ExercisesProps> = (props) => {
  const { modules, onChange, navigation, currentUser } = props;

  const { t } = useTranslation();

  const [expanded, setExpanded] = useState('');

  const selectHandler = (v: HeadsetSettingsModule, index: number) => {
    onChange(modules.map((el, i) => (i === index ? v : el)));
  };

  const addExerciseHandler = () => {
    if (modules.length < MAX_EXERCISES) {
      const empty: HeadsetSettingsModule = { name: ExerciseEnum.Empty, duration: 0, level: 0 };
      onChange([...modules, empty]);
    }
  };

  const changeUser = () => {
    navigation('superviserStartSession', true, { currentUser: currentUser });
  };

  const expandedHandler = (id: string) => {
    setExpanded(expanded === id ? '' : id);
  };

  const removeHandler = (id: number) => {
    onChange(modules.filter((_, i) => i !== id));
  };

  return (
    <>
      <SText style={styles.title_main}>{t('live_session_setup_session')}</SText>
      <View style={styles.container}>
        <View style={{ width: 330, alignItems: 'center' }}>
          <View style={{ width: '100%' }}>
            <SText style={styles.label}>{t('superviser_start_session_current_user')}</SText>
            <TouchableOpacity style={styles.user} onPress={changeUser}>
              <SText style={styles.username}>{currentUser ? getUsername(currentUser) : ''}</SText>
              <View style={styles.icon_wrapper}>
                <Image
                  source={require('../../../../../assets/icon_edit.png')}
                  style={styles.icon}
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>

        {modules.map((el, i) => (
          <Exercise
            key={i.toString()}
            module={el}
            i={i}
            length={modules.length}
            selectHandler={selectHandler}
            isExpanded={expanded === i.toString()}
            onExpanded={expandedHandler}
            onRemove={removeHandler}
          />
        ))}

        {modules.length < MAX_EXERCISES && (
          <SButton
            style={styles.text_button}
            type='text'
            label={`+ ${t('live_session_setup_add_exercise')}`}
            onPress={addExerciseHandler}
          />
        )}
      </View>
    </>
  );
};
