import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import LoginInput from '../elements/LoginInput';
import SButton from '../elements/SButton';
import STabBar3 from '../elements/STabBar3';
import { HasNavigation } from '../Navigation';
interface Props extends HasNavigation {
  type?: 'user' | 'placebo' | 'subadmin' | 'supervisor';
}

export default ({ type = 'user', dialog, navigation, style, ...rest }: Props) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordSecure, setPasswordSecure] = useState(true);

  const [login, setLogin] = useState('');
  const [email, setEmail] = useState('');
  const [firsName, setFirsName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userType, setUserType] = useState<typeof type>(type);

  const onTypeSelected = (value: string) => {
    setUserType(value as any as typeof type);
  };

  const onRegister = async () => {
    try {
      const api = await SenopiApi();
      dialog.showDialog(<ActivityIndicator color={'#FFFFFF'} />, { backgroundColor: '' });
      const user = await api.usersCreate({
        username: login,
        email: email,
        password: userType != 'supervisor' ? password : undefined,
        firstName: firsName,
        lastName: lastName,
        type: userType,
      });
      dialog.hideDialog();
      navigation('..');
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const roles =
    type == 'subadmin'
      ? new Map([
          ['subadmin', t('user_role_subadmin')],
          ['supervisor', t('user_role_supervisor')],
        ])
      : new Map([
          ['user', t('user_role_user')],
          ['placebo', t('user_role_placebo')],
        ]);

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} style={{ width: '100%' }} />
      <Text style={[styles.title]}>{t('create_title')}</Text>
      <View style={styles.form}>
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setFirsName}
          label={t('create_first_name') + '*'}
        />
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setLastName}
          label={t('create_last_name') + '*'}
        />
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setLogin}
          label={t('create_username') + '*'}
          textContentType='username'
        />
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setEmail}
          label={t('create_email') + '*'}
          textContentType='emailAddress'
        />
        {userType != 'supervisor' && (
          <LoginInput
            style={{ marginTop: 26 }}
            labelStyle={styles.label_style}
            onChangeText={setPassword}
            label={t('create_password') + '*'}
            icon={require(passwordSecure
              ? '../../assets/icon_password.png'
              : '../../assets/icon_password_open.png')}
            onIconPress={() => setPasswordSecure(!passwordSecure)}
            textContentType='password'
            secureTextEntry={passwordSecure}
            iconWidth={17}
            iconHeight={11}
          />
        )}
        <STabBar3
          style={{ marginTop: 26 }}
          isActive={userType == 'subadmin'}
          onSelected={onTypeSelected}
          selected={userType}
          items={roles}
        />

        <View style={{ flexGrow: 1 }} />
        <SButton style={styles.login_button} label={t('create_submit')} onPress={onRegister} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 20,
  },

  form: {
    width: 314,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 40,
  },

  login_button: {
    width: 256,
    marginTop: 31,
    marginBottom: 11,
  },

  label_style: {
    color: '#FFFFFF',
    fontSize: 18,
    fontFamily: 'SegoeRegular',
    opacity: 0.8,
  },
});
