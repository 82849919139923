import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import UserProfile from '../api/model/UserProfile';
import ListItem from '../elements/ListItem';
import { HasNavigation } from '../Navigation';
import { getUsername } from '../Utils';

const screenHeight = Dimensions.get('screen').height;

const UserProfileInfo = ({
  user,
  isSelected,
  onUserPress,
}: {
  user: UserProfile;
  isSelected: boolean;
  onUserPress: (user: UserProfile) => void;
}) => {
  return (
    <View style={styles.user_profile}>
      <TouchableOpacity style={{ flexGrow: 1 }} onPress={() => onUserPress(user)}>
        <Text style={styles.user_label} numberOfLines={1} ellipsizeMode='tail'>
          {getUsername(user)}
        </Text>
      </TouchableOpacity>
      {isSelected && (
        <Image
          source={require('../../assets/icon_selected.png')}
          style={{ width: 25, height: 25 }}
        />
      )}
    </View>
  );
};

interface UsersListProps extends HasNavigation {
  user?: UserProfile;
  usersFetch: UserProfile[];
  currentUser?: UserProfile;
  onUserPress: (user: UserProfile) => void;
  scrollStyle?: ViewStyle;
}

export default ({ currentUser, usersFetch, onUserPress, scrollStyle }: UsersListProps) => {
  const [text, setText] = useState<string>('');

  const { t } = useTranslation();

  const filter = (x: UserProfile) => {
    return (
      x.firstName?.match(`.*${text}.*`) ||
      x.lastName?.match(`.*${text}.*`) ||
      x.username?.match(`.*${text}.*`)
    );
  };

  return (
    <View style={[styles.container]}>
      {currentUser && (
        <View style={styles.current_user_container}>
          <Text style={styles.current_user_title}>
            {t('superviser_start_session_current_user')}
          </Text>
          <View style={styles.current_user_box}>
            <Text style={styles.current_user_subtitle} numberOfLines={1} ellipsizeMode='tail'>
              {getUsername(currentUser)}
            </Text>
            <Image
              source={require('../../assets/icon_selected.png')}
              style={styles.icon_selected}
            />
          </View>
        </View>
      )}
      <View style={styles.search}>
        <Image source={require('../../assets/icon_search.png')} style={styles.search_icon} />
        <TextInput
          style={styles.search_input}
          placeholder={t('users_search') || ''}
          onChangeText={setText}
        />
      </View>
      <ScrollView
        style={[styles.list, { height: screenHeight * (currentUser ? 0.55 : 0.7) }, scrollStyle]}
        showsVerticalScrollIndicator={false}
      >
        {usersFetch.filter(filter).map((x) => (
          <ListItem key={x.username}>
            <UserProfileInfo
              user={x}
              isSelected={x.username === currentUser?.username}
              onUserPress={onUserPress}
            />
          </ListItem>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    paddingHorizontal: 16,
    marginTop: 18,
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  list: {
    flexGrow: 1,
  },

  user_profile: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
  },

  user_photo: {
    width: 50,
    height: 50,
    borderWidth: 2,
    borderColor: '#48A9C5',
    borderRadius: 60,
  },

  user_label: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
    textAlign: 'left',
    flexGrow: 1,
  },

  user_sublabel: {
    color: '#FFFFFF',
    opacity: 0.5,
    fontSize: 15,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
    flexGrow: 1,
  },

  search: {
    backgroundColor: '#0A100FB2',
    borderRadius: 23,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: 18,
  },

  search_icon: {
    width: 21,
    height: 21,
    marginRight: 23,
  },

  search_input: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    flexGrow: 1,
  },

  current_user_container: {
    marginVertical: 10,
  },

  current_user_title: {
    color: '#FFFFFF',
    fontSize: 18,
    lineHeight: 22,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
    marginBottom: 5,
  },

  current_user_subtitle: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
    textAlign: 'center',
  },

  current_user_box: {
    paddingLeft: 35,
    paddingVertical: 20,
    paddingRight: 35,
    borderRadius: 9,
    borderWidth: 1,
    borderColor: '#FFFFFFB3',
    alignItems: 'center',
  },

  icon_selected: {
    width: 25,
    height: 25,
    position: 'absolute',
    right: 10,
    top: 20,
    bottom: 20,
  },
});
