import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Linking, StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import HeaderPane from '../elements/HeaderPane';
import IconButton from '../elements/IconButton';
import LanguagePicker from '../elements/LanguagePicker';
import SButton from '../elements/SButton';
import { HasNavigation } from '../Navigation';
import SubEditPasswordScreen from './SubEditPasswordScreen';

interface Props extends HasNavigation {
  title?: string;
  user?: UserProfile;
}

export default ({ navigation, user, style, ...rest }: Props) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [type, setType] = useState<string>('');

  const onLogout = async () => {
    const api = await SenopiApi();
    api.logout();
    navigation('login', false);
  };

  const loadProfile = async () => {
    const api = await SenopiApi(user);
    try {
      const user = api.userInfo;
      setFirstName(user.firstName || 'Anonymous');
      setLastName(user.lastName || '');
      setType(user.type || 'subadmin');
    } catch (e) {
      console.error(e);
    }
  };

  const adminEventsReport = async () => {
    const api = await SenopiApi();
    const downloadUrl = api.eventsAdminReport();
    await Linking.openURL(downloadUrl);
  };

  const eventsReport = async () => {
    const api = await SenopiApi(user);
    const downloadUrl = api.eventsReport();
    await Linking.openURL(downloadUrl);
  };

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <View style={[styles.container, style]}>
      {user && <HeaderPane onBackPressed={() => navigation('..')} />}
      <LanguagePicker navigation={navigation} dialog={rest.dialog} />
      <Image
        source={require('../../assets/logo_main.png')}
        style={{ width: 198, height: 50, marginTop: 48 }}
      />
      <Image source={require('../../assets/icon.png')} style={styles.photo} resizeMode='cover' />
      <Text style={styles.title}>
        {firstName} {lastName}
      </Text>
      <View style={styles.form}>
        {(type == 'subadmin' || type == 'admin') && (
          <SButton
            label={t('home_subadmin_users')}
            style={styles.form_button}
            onPress={() => navigation('subadminUsers', true, { user: user, source: 'user' })}
          />
        )}
        {type == 'subadmin' && (
          <SButton
            label={t('home_subadmin_vr')}
            style={styles.form_button}
            onPress={() => navigation('subadminVr', true, { user: user, source: 'user' })}
          />
        )}
        {type == 'subadmin' && (
          <SButton
            label={t('home_subadmin_events_report')}
            style={styles.form_button}
            onPress={eventsReport}
          />
        )}
        {type == 'subadmin' && (
          <SButton
            label={t('user_password_title')}
            style={styles.form_button}
            onPress={() => navigation(SubEditPasswordScreen, true, { user: user })}
          />
        )}
        {type == 'subadmin' && (
          <SButton
            label={t('live_session')}
            style={styles.form_button}
            onPress={() => navigation('subadminLiveSession', true, { user: user })}
          />
        )}
        {type == 'admin' && (
          <SButton
            label={t('home_subadmin_subadmins')}
            style={styles.form_button}
            onPress={() => navigation('subadminUsers', true, { source: 'subadmin' })}
          />
        )}
        {type == 'admin' && (
          <SButton
            label={t('home_subadmin_vr_admins')}
            style={styles.form_button}
            onPress={() => navigation('subadminVr', true, { source: 'subadmin' })}
          />
        )}
        {type == 'admin' && (
          <SButton
            label={t('adverse_reports')}
            style={styles.form_button}
            onPress={() => navigation('subadminAdverseReports', true)}
          />
        )}
        {type == 'admin' && (
          <SButton
            label={t('home_subadmin_events_report')}
            style={styles.form_button}
            onPress={adminEventsReport}
          />
        )}
        {type == 'admin' && (
          <SButton
            label={t('user_password_title')}
            style={styles.form_button}
            onPress={() => navigation(SubEditPasswordScreen, true, { user: user })}
          />
        )}
      </View>
      <IconButton
        label={t('home_sign_out')}
        icon={require('../../assets/icon_signout.png')}
        iconWidth={18}
        iconHeight={16}
        onPress={onLogout}
      />
      <View style={{ flexGrow: 1 }} />
      <Image
        source={require('../../assets/logo_developed_by.png')}
        resizeMode='contain'
        style={{ width: 97, height: 46, marginBottom: 10, marginTop: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  photo: {
    marginTop: 48,
    width: 120,
    height: 120,
    borderWidth: 4,
    borderColor: '#48A9C5',
    borderRadius: 60,
  },

  title: {
    color: '#48A9C5',
    fontSize: 29,
    fontFamily: 'SegoeRegular',
    marginTop: 19,
  },

  token: {
    color: '#FFFFFF',
    fontSize: 15,
    fontFamily: 'SegoeRegular',
    marginTop: 5,
  },

  form: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 49,
    marginBottom: 46,
  },

  form_button: {
    marginBottom: 17,
  },
});
