import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import CheckBoxLabel from '../elements/CheckBoxLabel';
import HeaderPane from '../elements/HeaderPane';
import LoginInput from '../elements/LoginInput';
import SButton from '../elements/SButton';
import STabBar3 from '../elements/STabBar3';
import { HasNavigation } from '../Navigation';
interface Props extends HasNavigation {
  user?: UserProfile;
}

export default ({ user, dialog, navigation, style, ...rest }: Props) => {
  const { t } = useTranslation();

  const [firsName, setFirsName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [userType, setUserType] = useState(user?.type as string);
  const [isPaused, setPaused] = useState(user?.isPaused as boolean);

  const onTypeSelected = (value: string) => {
    setUserType(value);
  };

  const onSave = async () => {
    try {
      const api = await SenopiApi();
      dialog.showDialog(<ActivityIndicator color={'#FFFFFF'} />, { backgroundColor: '' });

      if (user?.type == 'subadmin' || user?.type == 'supervisor') {
        await api.updateSubadmin(user!.username, {
          username: user.username,
          firstName: firsName,
          lastName: lastName,
          type: userType,
          isPaused: isPaused,
        });
      } else {
        await api.updateUser(user!.username, {
          username: user.username,
          firstName: firsName,
          lastName: lastName,
          type: userType,
          isPaused: isPaused,
        });
      }
      dialog.hideDialog();
      navigation('..');
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const roles =
    user?.type == 'subadmin' || user?.type == 'supervisor'
      ? new Map([
          ['subadmin', t('user_role_subadmin')],
          ['supervisor', t('user_role_supervisor')],
        ])
      : new Map([
          ['user', t('user_role_user')],
          ['placebo', t('user_role_placebo')],
        ]);

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} style={{ width: '100%' }} />
      <Text style={[styles.title]}>{t('create_title')}</Text>
      <View style={styles.form}>
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setFirsName}
          value={firsName}
          label={t('create_first_name') + '*'}
        />
        <LoginInput
          style={{ marginTop: 26 }}
          labelStyle={styles.label_style}
          onChangeText={setLastName}
          value={lastName}
          label={t('create_last_name') + '*'}
        />
        <STabBar3
          style={{ marginTop: 26 }}
          isActive={userType == 'subadmin' || userType == 'supervisor'}
          onSelected={onTypeSelected}
          selected={userType}
          items={roles}
        />
        <CheckBoxLabel
          label={t('user_pause')}
          isChecked={isPaused}
          style={{ marginTop: 15 }}
          onCheckedChange={setPaused}
        />

        <View style={{ flexGrow: 1 }} />
        <SButton style={styles.login_button} label={t('create_submit')} onPress={onSave} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 20,
  },

  form: {
    width: 314,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: 40,
  },

  login_button: {
    width: 256,
    marginTop: 31,
    marginBottom: 11,
  },

  label_style: {
    color: '#FFFFFF',
    fontSize: 18,
    fontFamily: 'SegoeRegular',
    opacity: 0.8,
  },
});
