import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, Text, View } from 'react-native';
import API, { UserAPI } from '../API';
import NewUserResponse from '../api/response/NewUserResponse';
import CheckBoxLabel from '../elements/CheckBoxLabel';
import HeaderPane from '../elements/HeaderPane';
import LoginInput from '../elements/LoginInput';
import SButton from '../elements/SButton';
import CreateUserStreams from '../model/CreateUserStreams';
import { HasNavigation } from '../Navigation';
import { createEventBatch } from '../PryvUtils';
interface Props extends HasNavigation {}

const initUser = async (login: string, password: string): Promise<LoginResponse> => {
  const api = await UserAPI(login);
  const loginUser = await api.login({
    appId: 'senopi-med',
    username: login,
    password: password,
  });

  await CreateUserStreams(api);
  return loginUser;
};

export default ({ navigation, style, ...rest }: Props) => {
  const { t } = useTranslation();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [firsName, setFirsName] = useState('');
  const [lastName, setLastName] = useState('');

  const [isAdmin, setAdmin] = useState(false);
  const [isPlacebo, setPlacebo] = useState(false);

  const onAdminChecked = () => {
    setAdmin(!isAdmin);
  };

  const onPlaceboChecked = () => {
    setPlacebo(!isPlacebo);
  };

  const onRegister = async () => {
    let registerUser: NewUserResponse | null = null;

    try {
      const registerApi = await API('reg');
      registerUser = await registerApi.register({
        appId: 'senopi-med',
        username: login,
        password: password,
      });
      console.log('User created');
      console.log(registerUser);

      if (registerUser.username == login) {
        const userLoginInfo = await initUser(login, password);
        const userApi = await UserAPI(login, userLoginInfo.token);
        const adminApi = await API();

        const accessInfo = await adminApi.accessCreate({
          name: login,
          permissions: [
            { streamId: 'tokens_read', level: 'contribute' },
            { streamId: 'tokens_headset', level: 'contribute' },
            { streamId: 'adverse_reports', level: 'contribute' },
          ],
        });

        const createData = [
          createEventBatch(['user_profile_first_name'], 'note/txt', firsName.trim()),
          createEventBatch(['user_profile_last_name'], 'note/txt', lastName.trim()),
          createEventBatch(
            ['user_profile_tokens'],
            'credentials/pryv-api-endpoint',
            accessInfo.access.apiEndpoint,
          ),
          createEventBatch(['user_profile_is_admin'], 'boolean/bool', isAdmin),
          createEventBatch(['user_profile_is_placebo'], 'boolean/bool', isPlacebo),
        ];
        const batchResult = await userApi.batchCall(createData);
        console.log(batchResult);
        navigation('adminHome');
      }
    } catch (e) {
      console.error(e);
      return;
    }
  };

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('adminHome')} style={{ width: '100%' }} />
      <Text style={[styles.title]}>{t('admin_create_user')}</Text>
      {/* <Text style={[styles.subtitle, { marginTop: 25, marginBottom: 30 }]} >{lang.reminders_subtitle}</Text> */}
      <View style={styles.form}>
        <LoginInput
          style={{ marginBottom: 35 }}
          onChangeText={setLogin}
          label={t('login_username')}
          icon={require('../../assets/icon_username.png')}
          textContentType='username'
          iconWidth={13}
          iconHeight={15}
        />
        <LoginInput
          onChangeText={setPassword}
          label={t('login_password')}
          icon={require('../../assets/icon_password.png')}
          iconWidth={17}
          iconHeight={11}
          textContentType='password'
          secureTextEntry={true}
        />
        <LoginInput
          style={{ marginTop: 35 }}
          onChangeText={setFirsName}
          label={t('login_first_name')}
          iconWidth={13}
          iconHeight={15}
        />
        <LoginInput
          style={{ marginTop: 35 }}
          onChangeText={setLastName}
          label={t('login_last_name')}
          iconWidth={13}
          iconHeight={15}
        />
        <CheckBoxLabel
          label={t('admin_create_user_admin')}
          isChecked={isAdmin}
          style={{ marginTop: 15 }}
          onCheckedChange={onAdminChecked}
        />
        <CheckBoxLabel
          label={t('admin_create_user_placebo')}
          isChecked={isPlacebo}
          style={{ marginTop: 15 }}
          onCheckedChange={onPlaceboChecked}
        />
        <SButton style={styles.login_button} label={t('login_create')} onPress={onRegister} />
      </View>
      <View style={{ flexGrow: 1 }} />
      <Image
        source={require('../../assets/logo_developed_by.png')}
        resizeMode='contain'
        style={{ width: 97, height: 46, marginBottom: 10 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#48A9C5',
    fontSize: 36,
    fontFamily: 'SegoeRegular',
    marginTop: 42,
  },

  subtitle: {
    color: '#FFFFFF',
    opacity: 0.8,
    fontSize: 17,
    fontFamily: 'SegoeLight',
  },

  form: {
    width: 260,
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 84,
    marginBottom: 69,
  },

  login_button: {
    marginTop: 86,
    marginBottom: 11,
  },

  password_forgot: {
    color: '#FFFFFF',
    fontSize: 17,
    fontFamily: 'SegoeLight',
    textAlign: 'center',
  },
});
