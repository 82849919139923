const path = "./"
export const balloonOrange = require(`${path}balloon_orange.png`);
export const balloonBlue = require(`${path}balloon_blue.png`);
export const balloonGreen = require(`${path}balloon_green.png`);
export const balloonPink = require(`${path}balloon_pink.png`);
export const balloonYellow = require(`${path}balloon_yellow.png`);
export const candyOrange = require(`${path}candy_orange.png`);
export const candyBlue = require(`${path}candy_blue.png`);
export const candyGreen = require(`${path}candy_green.png`);
export const candyYellow = require(`${path}candy_yellow.png`);
export const candyPink = require(`${path}candy_pink.png`);
export const heartOrange = require(`${path}heart_orange.png`);
export const heartBlue = require(`${path}heart_blue.png`);
export const heartGreen = require(`${path}heart_green.png`);
export const heartPink = require(`${path}heart_pink.png`);
export const heartYellow = require(`${path}heart_yellow.png`);
export const moonOrange = require(`${path}moon_orange.png`);
export const moonBlue = require(`${path}moon_blue.png`);
export const moonGreen = require(`${path}moon_green.png`);
export const moonPink = require(`${path}moon_pink.png`);
export const moonYellow = require(`${path}moon_yellow.png`);
export const starOrange = require(`${path}star_orange.png`);
export const starGreen = require(`${path}star_green.png`);
export const starPink = require(`${path}star_pink.png`);
export const starYellow = require(`${path}star_yellow.png`);
export const starBlue= require(`${path}star_blue.png`);