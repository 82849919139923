import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';
import { EnvironmentEnum } from '../../../../api/model/HeadsetSettings';
import SText from '../../../../elements/SText';
import { SelectButton } from '../SelectButton';
import { styles } from './styles';

interface EnvironmentProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

export const Environment: FC<EnvironmentProps> = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();
  return (
    <>
      <SText style={styles.title_main}>{t('live_session_setup_session')}</SText>
      <SText style={styles.title}>{t('live_session_setup_select_environment')}</SText>
      <View style={styles.container}>
        <View style={styles.row}>
          <SelectButton type={EnvironmentEnum.Beach} selected={value} onChange={onChange} />
          <SelectButton type={EnvironmentEnum.Mountain} selected={value} onChange={onChange} />
        </View>
        <View style={styles.row}>
          <SelectButton type={EnvironmentEnum.Forest} selected={value} onChange={onChange} />
          <SelectButton type={EnvironmentEnum.Sightseeing} selected={value} onChange={onChange} />
        </View>
      </View>
    </>
  );
};
