import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    position: 'relative',
    width: 108,
    height: 108,
    borderRadius: 108,
    alignItems: 'center',
    justifyContent: 'center',
  },
  inner: {
    width: 100,
    height: 100,
    borderRadius: 100,
  },
  select: {
    position: 'absolute',
    height: 100,
    width: 100,
    borderRadius: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  check: {
    width: 45,
    height: 32,
  },
  label: {
    fontSize: 18,
    color: '#fff',
    textAlign: 'center',
    marginTop: 15,
    lineHeight: 22,
  },
});
