import { FC, useEffect, useRef, useState } from 'react';
import { Animated, Image, TouchableOpacity } from 'react-native';
import { OPEN_ANIMATION_DURATION } from '../styles';
import { styles } from './styles';

export enum ButtonTypesEnum {
  Sound = 'Sound',
  Next = 'Next',
  Pause = 'Pause',
  Repeat = 'Repeat',
  Open = 'Open',
}

interface Props {
  style?: any;
  onPress: () => void;
  onPressWithState?: () => void;
  type: ButtonTypesEnum;
  state?: boolean;
}

export const Button: FC<Props> = (props) => {
  const { style, type, onPress, onPressWithState, state } = props;

  const [stateValue, setStateValue] = useState(!!state);

  const onPressHandler = () => {
    if (onPressWithState) {
      if (stateValue) {
        onPressWithState();
      } else {
        onPress();
      }
      setStateValue((prev) => !prev);
    } else {
      onPress();
    }
  };

  useEffect(() => {
    setStateValue(!!state);
  }, [state]);

  return (
    <Animated.View style={[styles.container, style]}>
      <TouchableOpacity style={styles.inner} onPress={onPressHandler}>
        {type === ButtonTypesEnum.Sound &&
          (stateValue ? (
            <Image
              style={{ width: 41, height: 41 }}
              source={require('../../../../assets/control_session/unmute.png')}
            />
          ) : (
            <Image
              style={{ width: 30, height: 28 }}
              source={require('../../../../assets/control_session/volume-mute-solid.png')}
            />
          ))}
        {type === ButtonTypesEnum.Next && (
          <Image
            style={{ width: 30, height: 29 }}
            source={require('../../../../assets/control_session/next.png')}
          />
        )}

        {type === ButtonTypesEnum.Pause &&
          (stateValue ? (
            <Image
              style={{ height: 29, width: 34, marginLeft: 5 }}
              source={require('../../../../assets/control_session/play.png')}
            />
          ) : (
            <Image
              style={{ width: 20, height: 25 }}
              source={require('../../../../assets/control_session/pause.png')}
            />
          ))}
        {type === ButtonTypesEnum.Repeat && (
          <Image
            style={{ width: 28 * 1.5, height: 29 * 1.5 }}
            source={require('../../../../assets/control_session/repeat.png')}
          />
        )}
      </TouchableOpacity>
    </Animated.View>
  );
};

interface OpenButtonProps {
  isOpen: boolean;
  style?: any;
  onPress: () => void;
}

export const OpenButton: FC<OpenButtonProps> = (props) => {
  const { isOpen, style, onPress } = props;

  const animation = useRef(new Animated.Value(0)).current;

  const rotateAnimation = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-90deg'],
  });

  const opacityInAnimation = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });
  const opacityOutAnimation = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0],
  });

  const onOpenHandler = () => {
    if (isOpen) {
      setTimeout(() => {
        Animated.timing(animation, {
          toValue: 0,
          duration: OPEN_ANIMATION_DURATION,
          useNativeDriver: false,
        }).start();
      }, 1);
    } else {
      Animated.timing(animation, {
        toValue: 1,
        duration: OPEN_ANIMATION_DURATION,
        useNativeDriver: false,
      }).start();
    }
    onPress();
  };

  return (
    <Animated.View style={[styles.container, style]}>
      <TouchableOpacity style={styles.inner} onPress={onOpenHandler}>
        <Animated.Image
          style={{
            position: 'absolute',
            width: 27,
            height: 28.7,
            opacity: opacityInAnimation,
            transform: [{ rotate: rotateAnimation }],
          }}
          source={require('../../../../assets/control_session/cross.png')}
        />
        <Animated.Image
          style={{
            width: 29,
            height: 25,
            transform: [{ rotate: rotateAnimation }],
            opacity: opacityOutAnimation,
          }}
          source={require('../../../../assets/control_session/burger.png')}
        />
      </TouchableOpacity>
    </Animated.View>
  );
};
