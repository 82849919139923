import { FC, useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import UserProfile from '../api/model/UserProfile';
import SenopiApi from '../api/SenopiApi';
import { Dialog } from '../elements/Dialog';
import HeaderPane from '../elements/HeaderPane';
import SButton from '../elements/SButton';
import { HasNavigation } from '../Navigation';
import { screenHeight } from '../Utils';
import SuperviserCreateUser from './SuperviserCreateUser';
import UsersList from './SuperviserSessionUsersList';
import { useTranslation } from 'react-i18next';

interface Props extends HasNavigation {
  user?: UserProfile;
}

export default ({ user, navigation, style, dialog, ...rest }: Props) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserProfile>();
  const [headset, setHeadset] = useState<UserProfile | null>(null);
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const [users, setUsers] = useState<UserProfile[]>([]);

  const { t } = useTranslation();

  const onUserPress = (user: UserProfile): void => {
    assignUser(user);
  };

  useEffect(() => {
    const load = async () => {
      dialog.showLoading();
      const api = await SenopiApi(user);
      const result = await api.headsets();
      const userList = await api.users();
      setUsers(userList);
      if (result.length) {
        setCurrentUser(userList.find((u) => u.username === result[0].user?.username));
        setHeadset(result[0]?.headset || null);
      }

      dialog.hideLoading();
    };

    load();
  }, []);

  const assignUser = async (u: UserProfile): Promise<void> => {
    if (!headset || !u) {
      return;
    }
    dialog.showLoading();
    const api = await SenopiApi(user);
    try {
      await api.assignUser(headset.username as string, u.username as string);
      setCurrentUser(u);
    } catch (e) {
      //@ts-ignore
      if (e.code === 400) {
        showAnotherDialog();
      }
    }
    dialog.hideLoading();
  };

  const starSession = async (): Promise<void> => {
    if (!currentUser) {
      return;
    }
    dialog.showLoading();
    const api = await SenopiApi(user);
    const result = await api.liveSessions();
    const session = result.find((s) => s.userAccessInfo.username === currentUser.username);
    dialog.hideLoading();
    if (!session) {
      showTurnOnDialog();
    } else {
      navigation('subadminExerciseInfo', true, {
        exercise: session.exercise,
        user: currentUser,
      });
    }
  };

  const showTurnOnDialog = () => {
    setDialogMessage(t('superviser_start_session_turn_on') || '');
    setDialogVisible(true);
  };

  const showAnotherDialog = () => {
    setDialogMessage(t('superviser_start_session_another_user') || '');
    setDialogVisible(true);
  };

  return (
    <View style={[styles.container, style]}>
      <HeaderPane onBackPressed={() => navigation('..')} />
      <UsersList
        dialog={dialog}
        currentUser={currentUser}
        user={user}
        usersFetch={users}
        {...rest}
        navigation={navigation}
        onUserPress={onUserPress}
        scrollStyle={styles.user_list}
      />
      <View style={styles.buttons}>
        <Text
          style={styles.create_user}
          onPress={() =>
            navigation(SuperviserCreateUser, true, {
              user: user,
              size: users.length,
              onSave: (form: any) => console.log(form),
            })
          }
        >
          {'+ ' + t('home_superviser_create_user')}
        </Text>
        <SButton
          label={t('superviser_start_session_start')}
          style={styles.start_button}
          onPress={starSession}
          disabled={!currentUser || !headset}
        />
      </View>
      <DialogInfo
        isViseble={dialogVisible}
        setIsViseble={setDialogVisible}
        message={dialogMessage}
      />
    </View>
  );
};

interface DialogInfoProps {
  isViseble: boolean;
  setIsViseble: (isViseble: boolean) => void;
  message: string;
}
const DialogInfo: FC<DialogInfoProps> = (props) => {
  const { isViseble, setIsViseble, message } = props;
  const { t } = useTranslation();
  return (
    <Dialog isVisible={isViseble} onDismiss={() => setIsViseble(false)}>
      <Text style={styles.info_title}>{message}</Text>
      <SButton
        style={{ width: '100%' }}
        label={t('continue')}
        onPress={() => setIsViseble(false)}
      />
    </Dialog>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },

  title: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center',
  },

  create_user: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
    color: '#48A9C5',
    fontSize: 20,
    lineHeight: 24,
    fontFamily: 'SegoeRegular',
  },

  start_button: {
    marginBottom: 30,
    width: '80%',
    alignSelf: 'center',
  },

  search: {
    backgroundColor: '#0A100FB2',
    borderRadius: 23,
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 16,
    paddingLeft: 16,
    marginBottom: 18,
  },

  search_icon: {
    width: 21,
    height: 21,
    marginRight: 23,
  },

  search_input: {
    color: '#FFFFFF',
    fontSize: 20,
    fontFamily: 'SegoeRegular',
    flexGrow: 1,
  },

  info_title: {
    color: '#FFFFFF',
    fontSize: 20,
    lineHeight: 22,
    textAlign: 'center',
    fontFamily: 'SegoeRegular',
    marginVertical: 80,
    marginHorizontal: '10%',
  },

  buttons: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },

  user_list: {
    paddingBottom: 100,
    height: screenHeight * 0.57,
  },
});
