const path = "./"
export const blueLeft = require(`${path}blue_left.png`);
export const blueRight = require(`${path}blue_right.png`);
export const pinkLeft = require(`${path}pink_left.png`);
export const pinkRight = require(`${path}pink_right.png`);
export const orangeLeft = require(`${path}orange_left.png`);
export const orangeRight = require(`${path}orange_right.png`);
export const greenLeft = require(`${path}green_left.png`);
export const greenRight = require(`${path}green_right.png`);
export const whiteLeft = require(`${path}white_left.png`);
export const whiteRight = require(`${path}white_right.png`);
export const yellowLeft = require(`${path}yellow_left.png`);
export const yellowRight = require(`${path}yellow_right.png`);