import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Image,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from 'react-native';
import { HasNavigation } from '../Navigation';
import DatePicker from './DatePicker';

interface SDatePickerProps extends TouchableOpacityProps, HasNavigation {
  value?: Date;
  style?: StyleProp<ViewStyle>;
  onDateChange: (date: Date) => void;
}

const SDatePicker: FC<SDatePickerProps> = ({ value, onDateChange, style, ...rest }) => {
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);

  const date = value;
  const day = date ? date.getDate().toString().padStart(2, '0') : t('report_date_day');
  const month = date ? (date.getMonth() + 1).toString().padStart(2, '0') : t('report_date_month');
  const year = date ? date.getFullYear().toString().padStart(2, '0') : t('report_date_year');
  const onPress = () => {
    setShowCalendar(!showCalendar);
  };

  const onDatePicked = (date: Date) => {
    setShowCalendar(false);
    onDateChange(date);
  };

  return (
    <View>
      <TouchableOpacity onPress={onPress} style={[styles.widget, style]} {...rest}>
        <Text style={[styles.label]}>{day}</Text>
        <Text style={[styles.label, styles.labelSeparator]}>/</Text>
        <Text style={[styles.label]}>{month}</Text>
        <Text style={[styles.label, styles.labelSeparator]}>/</Text>
        <Text style={[styles.label]}>{year}</Text>
        <Image source={require('../../assets/icon_calendar.png')} style={styles.icon} />
      </TouchableOpacity>
      {showCalendar && (
        <DatePicker onDateChange={onDatePicked} value={value} onOnCloseRequest={onPress} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  widget: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#FFFFFFB2',
    paddingBottom: 10,
  },

  icon: {
    width: 20,
    height: 20,
    marginStart: 15,
  },

  label: {
    fontFamily: 'SegoeRegular',
    fontSize: 19,
    textAlign: 'center',
    color: '#FFFFFF',
    marginStart: 5,
    marginEnd: 5,
  },

  labelSeparator: {
    color: '#48A9C5',
  },
});

export default SDatePicker;
